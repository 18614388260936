import JSZip from "jszip";
import { memo, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSampleSet } from "../../../hooks/samples";
import Sample from "../../../types/sample";
import Plot, { Dataset } from "../../Common/Plot";
import { SelectionMap } from "./SampleSelect";

interface SampleDetailsProps {
  selectedSamples: SelectionMap;
  files: JSZip | null;
  samples?: Sample[];
  title: string;
}

function SampleDetails({
  selectedSamples,
  samples,
  files,
  title,
}: SampleDetailsProps) {
  const styles = useStyles();
  const sampleSet = useSampleSet(files, selectedSamples);
  const [revision, setRevision] = useState<number>(0);
  useEffect(() => {
    setRevision(revision + 1);
  }, [sampleSet, samples, files]);

  let detectorDatasets: Dataset[] = [];
  if (samples?.length) {
    detectorDatasets = samples
      .filter((s) => selectedSamples[s.name])
      .map((s) => s.results.detectors[0].readings);
  } else {
    sampleSet.forEach((sample) => {
      detectorDatasets.push(sample.results.detectors[1].readings);
    });
  }

  return (
    <div className={styles.root}>
      <Plot
        data={detectorDatasets}
        revision={revision}
        height={500}
        title={title}
      />
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    // width: 1000,
    height: 500,
  },
});
export default memo(SampleDetails);

import classNames from "classnames";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";
import { InputElement } from "../../types/dom";

interface InputProps extends InputElement {}

function Input(props: InputProps) {
  const styles = useStyles();
  const { className, ref, ...rest } = props;

  return (
    <input className={classNames(styles.root, className)} ref={ref} {...rest} />
  );
}

const useStyles = createUseStyles({
  root: {
    border: "none",
    padding: theme.spacing[1],
    borderRadius: theme.spacing[0],
  },
});
export default Input;

import { memo } from "react";
import { createUseStyles } from "react-jss";
import { DivElement } from "../../types/dom";
import { SemanticVersion } from "../../types/oven";
import Label from "../Common/Label";

interface FirmwareVersionProps extends SemanticVersion, DivElement {}

function FirmwareVersion(props: FirmwareVersionProps) {
  const styles = useStyles();
  const { major, minor, patch, ref, ...rest } = props;

  return (
    <Label
      value="Firmware"
      {...rest}
      classes={{ title: styles.title, content: styles.content }}
    >
      <span>{major}</span>
      {"."}
      <span>{minor}</span>
      {"."}
      <span>{patch}</span>
    </Label>
  );
}

const useStyles = createUseStyles({
  title: {
    fontSize: 10,
  },
  content: {
    fontSize: 12,
    fontWeight: "normal",
  },
});

export default memo(FirmwareVersion);

import classNames from "classnames";
import { memo } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";
import { DioChannelState } from "../../types/digital-io";
import { DivElement } from "../../types/dom";
import Switch from "../Common/Switch";

interface DigitalIoPinProps extends DivElement, DioChannelState {
  className?: string;
  onToggle: (pin: number, on: boolean) => void;
}

function DigitalIoPin(props: DigitalIoPinProps) {
  const { className, on, pin, onToggle, ...rest } = props;

  const styles = useStyles();

  const handleToggle = (on: boolean) => {
    onToggle(pin, on);
  };

  return (
    <div className={classNames(styles.root, className)} {...rest}>
      <label className={styles.label}>ch {pin + 1}</label>
      <Switch className={styles.switch} on={on} onToggle={handleToggle} />
    </div>
  );
}

const useStyles = createUseStyles({
  root: {},
  label: {
    textTransform: "uppercase",
    fontSize: 12,
    color: theme.color.primary[0],
    fontWeight: "bold",
    paddingLeft: theme.spacing[1],
  },
  switch: {
    marginTop: theme.spacing[0],
  },
});
export default memo(DigitalIoPin);

import classNames from "classnames";
import { createUseStyles } from "react-jss";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { theme } from "../../style/theme";

interface LinkProps extends RouterLinkProps {}

function Link(props: LinkProps) {
  const styles = useStyles();
  const { children, className, ...rest } = props;
  return (
    <RouterLink className={classNames(styles.root, className)} {...rest}>
      {children}
    </RouterLink>
  );
}

const useStyles = createUseStyles({
  root: {
    textDecoration: "none",
    color: "inherit",
  },
});
export default Link;

export const theme = {
  color: {
    primary: [
      "#003E6B",
      "#0A558C",
      "#0F609B",
      "#186FAF",
      "#2680C2",
      "#4098D7",
      "#62B0E8",
      "#84C5F4",
      "#B6E0FE",
      "#DCEEFB"
    ],
    accent: [
      "#8D2B0B",
      "#B44D12",
      "#CB6E17",
      "#DE911D",
      "#F0B429",
      "#F7C948",
      "#FADB5F",
      "#FCE588",
      "#FFF3C4",
      "#FFFBEA",
    ],
    neutral: [
      "#102A43",
      "#243B53",
      "#334E68",
      "#486581",
      "#627D98",
      "#829AB1",
      "#9FB3C8",
      "#BCCCDC",
      "#D9E2EC",
      "#F0F4F8",
      "#FFF"
    ],
    support: {
      cyan: [
        "#044E54",
        "#0A6C74",
        "#0E7C86",
        "#14919B",
        "#2CB1BC",
        "#38BEC9",
        "#54D1DB",
        "#87EAF2",
        "#BEF8FD",
        "#E0FCFF",
      ],
      red: [
        "#610404",
        "#780A0A",
        "#911111",
        "#A61B1B",
        "#BA2525",
        "#D64545",
        "#E66A6A",
        "#F29B9B",
        "#FACDCD",
        "#FFEEEE",
      ],
    }
  },
  spacing: [
    4,
    8,
    12,
    16,
    24,
    32,
    48,
    64,
    96,
    128,
    192,
    256,
    384,
    512,
    640,
    768
  ],
} as const;

export const jssTheme = {};
import { useState, useEffect, memo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { createUseStyles } from "react-jss";
import PlotlyPlot from "react-plotly.js";
import { theme } from "../../style/theme";
import { Viewport } from "../../types/plot";

const layoutDefault: Partial<Plotly.Layout> = {
  legend: {
    x: 1,
    y: 1,
    xanchor: "right",
  },
  margin: {
    t: 50,
    b: 40,
    l: 60,
    r: 30,
    pad: 10,
  },
};

export type Dataset = Partial<Plotly.PlotData>;

export type WindowZoomLayout = {
  "xaxis.range[0]": number;
  "xaxis.range[1]": number;
  "yaxis.range[0]": number;
  "yaxis.range[1]": number;
};

export type XZoomLayout = {
  "xaxis.range[0]": number;
  "xaxis.range[1]": number;
};

export type YZoomLayout = {
  "yaxis.range[0]": number;
  "yaxis.range[1]": number;
};

export type ResetLayout = {
  "xaxis.autorange": true;
  "yaxis.autorange": true;
};

export type ControlTypeRelayout = { dragmode: string };

export type RelayoutEvent =
  | WindowZoomLayout
  | XZoomLayout
  | YZoomLayout
  | ResetLayout
  | ControlTypeRelayout;

interface PlotProps {
  data: Dataset[];
  revision: number;
  height?: number;
  title?: string;
  width?: number;
  onChangeViewport?: (viewport: Viewport) => void;
}

function Plot(props: PlotProps) {
  const styles = useStyles();
  const [viewport, setViewport] = useState<Viewport>({
    x: undefined,
    y: undefined,
  });
  const [layout, setLayout] = useState<Partial<Plotly.Layout>>({
    ...layoutDefault,
    xaxis: { range: viewport.x },
    yaxis: { range: viewport.y },
    width: props.width,
    height: props.height,
  });

  useEffect(() => {
    const nextLayout = {
      ...layout,
      xaxis: { range: viewport.x },
      yaxis: { range: viewport.y },
      width: props.width,
      title: props.title,
    };
    setLayout(nextLayout);
    if (props.onChangeViewport) props.onChangeViewport(viewport);
  }, [props.width, viewport, props.title]);

  const handleRelayout = (e: RelayoutEvent) => {
    //@ts-ignore
    if (e.dragmode) return;
    //@ts-ignore
    if (e["xaxis.autorange"])
      return setViewport({
        x: undefined,
        y: undefined,
      });

    let x: [number, number] | undefined = undefined;
    let y: [number, number] | undefined = undefined;
    //@ts-ignore
    if (e["yaxis.range[0]"]) {
      //@ts-ignore
      y = [e["yaxis.range[0]"], e["yaxis.range[1]"]];
    } else {
      y = viewport.y;
    }

    //@ts-ignore
    if (e["xaxis.range[0]"]) {
      //@ts-ignore
      x = [e["xaxis.range[0]"], e["xaxis.range[1]"]];
    } else {
      x = viewport.x;
    }

    setViewport({ x, y });
  };

  return (
    <div className={styles.root}>
      {/* @ts-ignore */}
      <PlotlyPlot
        className={styles.plot}
        data={props.data}
        layout={layout}
        //@ts-ignore
        onRelayout={handleRelayout}
      />
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    width: "100%",
  },
  plot: {},
  "@global": {
    ".main-svg": {
      borderRadius: theme.spacing[2],
    },
    ".modebar-btn--logo": {
      display: "none !important",
    },
  },
});
export default memo(Plot);

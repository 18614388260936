import { isIP } from "is-ip";
import { useState, useEffect } from "react";
import { fetchLoadedMethod, fetchRunStatus } from "../server/run";
import { Method, Run } from "../types/method";

export function useCurrentRun(ip: string, port: number): Run | null {
  const [run, setRun] = useState<Run | null>(null);

  useEffect(() => {
    async function updateRunStatus() {
      if (!isIP(ip)) return;
      const run = await fetchRunStatus(ip, port);
      setRun(run);
    }

    updateRunStatus();
  }, [ip, port]);

  return run;
}

export function useLoadedMethod(ip: string, port: number): Method | null {
  const [method, setMethod] = useState<Method | null>(null);

  useEffect(() => {
    async function fetchMethod() {
      if (!isIP(ip)) return;
      const method = await fetchLoadedMethod(ip, port);
      setMethod(method);
    }

    fetchMethod();
  }, [ip, port]);

  return method;
}

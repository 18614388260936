import classNames from "classnames";
import { memo, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { colors } from "../../../colors";
import { theme } from "../../../style/theme";
import Label from "../../Common/Label";

export type SelectionMap = { [id: string]: boolean };

interface SampleSelectProps {
  sampleIds: string[];
  onChange?: (selection: SelectionMap) => void;
}

const initializeSelectionMap = (
  ids: string[],
  defaultSelected: boolean
): SelectionMap => {
  const selectionMap: SelectionMap = {};
  for (let id of ids) {
    selectionMap[id] = defaultSelected;
  }
  return selectionMap;
};

const areAllOptionsSelected = (
  ids: string[],
  selectedMap: SelectionMap
): boolean => {
  for (let id of ids) {
    if (!selectedMap[id]) return false;
  }
  return true;
};

const areNoOptionsSelected = (
  ids: string[],
  selectedMap: SelectionMap
): boolean => {
  for (let id of ids) {
    if (selectedMap[id]) return false;
  }
  return true;
};

function SampleSelect({ sampleIds, onChange }: SampleSelectProps) {
  const styles = useStyles();
  const [selected, setSelected] = useState<SelectionMap>(
    initializeSelectionMap(sampleIds, false)
  );

  useEffect(() => {
    setSelected(initializeSelectionMap(sampleIds, false));
  }, [sampleIds]);

  const clickRow = (id: string) => () => {
    const nextSelection = { ...selected, [id]: !selected[id] };
    setSelected(nextSelection);
    if (onChange) onChange(nextSelection);
  };

  const batchSelectAll = () => {
    const nextSelection: SelectionMap = initializeSelectionMap(sampleIds, true);
    setSelected(nextSelection);
    if (onChange) onChange(nextSelection);
  };

  const batchSelectNone = () => {
    const nextSelection: SelectionMap = initializeSelectionMap(
      sampleIds,
      false
    );
    setSelected(nextSelection);
    if (onChange) onChange(nextSelection);
  };

  return (
    <div className={styles.root}>
      <div className={styles.batchSelectContainer}>
        <Label
          classes={{ content: styles.batchSelectOptions }}
          value="Bulk Select"
        >
          <span className={styles.batchSelectOption} onClick={batchSelectAll}>
            All{" "}
            <input
              readOnly
              type="checkbox"
              checked={
                Boolean(sampleIds.length) &&
                areAllOptionsSelected(sampleIds, selected)
              }
            />
          </span>
          <span className={styles.batchSelectOption} onClick={batchSelectNone}>
            None{" "}
            <input
              readOnly
              type="checkbox"
              checked={areNoOptionsSelected(sampleIds, selected)}
            />
          </span>
        </Label>
      </div>
      <div className={styles.sampleRows}>
        {sampleIds.map((id, i) => (
          <div
            key={id}
            className={classNames(
              styles.sampleRow,
              Boolean(selected[id]) && styles.activeRow
            )}
            onClick={clickRow(id)}
          >
            <div>{id}</div>
            <input
              readOnly
              type="checkbox"
              checked={Boolean(selected[id])}
              style={{
                accentColor: colors[i % colors.length].value,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    userSelect: "none",
    borderRadius: theme.spacing[0],
    border: `solid 1px ${theme.color.neutral[7]}`,
    height: 500,
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    width: "max-content",
    minWidth: 200,
  },
  batchSelectContainer: {
    borderBottom: `solid 1px ${theme.color.neutral[7]}`,
    padding: theme.spacing[3],
  },
  batchSelectOptions: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: theme.spacing[2],
    // justifyContent: "space-between",
    marginTop: theme.spacing[1],
    fontWeight: "normal",
  },
  batchSelectOption: {
    cursor: "pointer",
  },
  sampleRows: {
    display: "grid",
    gridGap: theme.spacing[0],
    overflowY: "scroll",
    padding: theme.spacing[3],
  },
  sampleRow: {
    display: "grid",
    padding: theme.spacing[0],
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: theme.spacing[2],
    justifyContent: "space-between",
    fontSize: 16,
    cursor: "pointer",
    borderRadius: theme.spacing[0],
    color: theme.color.primary[2],
    width: "100%",
  },
  activeRow: {
    background: theme.color.primary[9],
  },
});
export default memo(SampleSelect);

import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { memo } from "react";
import { createUseStyles } from "react-jss";
import relativeTime from "dayjs/plugin/relativeTime";

import Label from "../Common/Label";
import { theme } from "../../style/theme";
import { DivElement } from "../../types/dom";
import classNames from "classnames";
import EditableField from "../Common/EditableField";
import { SetpointRamp } from "../../types/controller";
import { truncate } from "../../formatting";

dayjs.extend(relativeTime);

interface SetpointRampForm extends Partial<SetpointRamp>, DivElement {
  updatedAt: number;
  units: {
    setpoint: string;
    ramp: string;
  };
}

function SetpointRampForm({
  initial,
  final,
  rate,
  updatedAt,
  current,
  className,
  units,
  ...rest
}: SetpointRampForm) {
  const styles = useStyles();

  return (
    <div className={classNames(styles.root, className)} {...rest}>
      <Label
        classes={{ title: styles.sectionTitle, content: styles.rampContent }}
        value={
          <div>
            <FontAwesomeIcon icon={faBullseye} /> Setpoint ({units.setpoint})
          </div>
        }
        title={`Updated ${dayjs(updatedAt).fromNow()}`}
      >
        <Label value="Target" className={styles.bold}>
          {truncate(current)}
        </Label>
        <Label value="Ramp">
          {truncate(initial)}
          <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
          {truncate(final)}
        </Label>
        <Label value={`Rate (${units.ramp})`}>
          <EditableField value={(rate || 0).toFixed(2)} />
        </Label>
      </Label>
      {/* <UpdateButtons
        className={classNames(styles.updateButtons, styles.hidden)}
      /> */}
    </div>
  );
}

const useStyles = createUseStyles({
  root: {},
  sectionTitle: {
    marginBottom: theme.spacing[0],
    color: theme.color.primary[2],
  },
  rampContent: {
    display: "grid",
    gridGap: theme.spacing[3],
    gridTemplateColumns: "repeat(3, max-content)",
  },
  units: {
    color: theme.color.neutral[3],
    fontSize: 12,
  },
  updateButtons: {
    marginTop: theme.spacing[1],
  },
  hidden: {
    // visibility: "hidden",
  },
  arrow: {
    fontSize: 12,
    color: "#7b7b7b",
    padding: `0 ${theme.spacing[0]}px`,
  },
  bold: {
    fontWeight: "bold",
  },
});

export default memo(SetpointRampForm);

import { isIP } from "is-ip";
import { useEffect, useState } from "react";
import { fetchOvenIds, fetchOvens } from "../server/ovens";
import { BackplaneChannel, OvenState } from "../types/oven";

export function useOvenIndex(
  ip: string,
  port: number
): BackplaneChannel[] | null {
  const [ovenIds, setOvenIds] = useState<BackplaneChannel[] | null>(null);

  useEffect(() => {
    async function getOvenIds() {
      if (!isIP(ip)) return;
      const ids = await fetchOvenIds(ip, port);
      setOvenIds(ids);
    }

    getOvenIds();
  }, [ip, port]);

  return ovenIds;
}

export function useOvens(ip: string, port: number): OvenState[] | null {
  const ids = useOvenIndex(ip, port);
  const [ovens, setOvens] = useState<OvenState[] | null>(null);

  useEffect(() => {
    async function getOvens() {
      if (!isIP(ip)) return;
      if (ids === null) return;
      const ovens = await fetchOvens(ip, port, ids);
      setOvens(ovens);
    }

    getOvens();
  }, [ids, ip, port]);

  return ovens;
}

export function useOvenDetails(
  ip: string,
  port: number,
  id: BackplaneChannel
): OvenState | null {
  const [oven, setOven] = useState<OvenState | null>(null);

  useEffect(() => {
    async function getOven() {
      if (!isIP(ip)) return;
      const [oven] = await fetchOvens(ip, port, [id]);
      if (oven) setOven(oven);
    }

    getOven();
  }, [id, ip, port]);

  return oven;
}

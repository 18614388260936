import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { truncate } from "../../../formatting";
import { theme } from "../../../style/theme";
import { FormField } from "../../../types/dom";
import { ServiceEndpoint } from "../../../types/network";
import { OvenState } from "../../../types/oven";
import Button from "../../Common/Button";
import DecoratedInput from "../../Common/DecoratedInput";
import Label from "../../Common/Label";

type OvenSetpointRamp = Omit<OvenState["temperature"], "actual">;
type SetpointForm = {
  initial: FormField;
  final: FormField;
  rate: FormField;
};
type SetpointField = keyof SetpointForm;

export interface SetSetpointProps extends OvenSetpointRamp, ServiceEndpoint {}

function SetSetpoint({ ip, port, setpoint }: SetSetpointProps) {
  const styles = useStyles();
  const [setpointForm, setSetpointField] = useState<SetpointForm>({
    initial: {
      value: String(truncate(setpoint.initial, 2)),
      dirty: false,
    },
    final: {
      value: String(truncate(setpoint.final, 2)),
      dirty: false,
    },
    rate: {
      value: String(truncate(setpoint.rate, 2)),
      dirty: false,
    },
  });
  const { id } = useParams();

  const isSetpointFieldDirty =
    setpointForm.initial.dirty ||
    setpointForm.final.dirty ||
    setpointForm.rate.dirty;

  const updateSetpointInput =
    (field: SetpointField) => (event: ChangeEvent<HTMLInputElement>) => {
      setSetpointField({
        ...setpointForm,
        [field]: {
          value: event.target.value,
          dirty: true,
        },
      });
      console.log("change", field);
    };

  const postRampSetpoint = () => {
    if (!isSetpointFieldDirty) return;
    console.log(`http://${ip}:${port}/oven/${id}/ramp`);
    const body = JSON.stringify({
      value: {
        initial: Number(setpointForm.initial.value),
        final: Number(setpointForm.final.value),
        rate: Number(setpointForm.rate.value),
      },
    });
    console.log(body);
    fetch(`http://${ip}:${port}/oven/${id}/ramp`, {
      method: "POST",
      body,
      headers: { "Content-Type": "application/json" },
    });
    setSetpointField({
      initial: {
        value: String(truncate(setpoint.initial, 2)),
        dirty: false,
      },
      final: {
        value: String(truncate(setpoint.final, 2)),
        dirty: false,
      },
      rate: {
        value: String(truncate(setpoint.rate, 2)),
        dirty: false,
      },
    });
  };

  return (
    <div className={styles.root}>
      <Label classes={{ content: styles.content }} value="Setpoint">
        <DecoratedInput
          name="start-setpoint"
          className={styles.setpointInput}
          classes={{ decoration: styles.setpointInputLabel }}
          decoration="Start"
          type="number"
          min={0}
          onChange={updateSetpointInput("initial")}
          value={
            setpointForm.initial.dirty
              ? setpointForm.initial.value
              : setpoint.initial
          }
        />
        <DecoratedInput
          name="end-setpoint"
          className={styles.setpointInput}
          classes={{ decoration: styles.setpointInputLabel }}
          decoration="End"
          type="number"
          min={0}
          onChange={updateSetpointInput("final")}
          value={
            setpointForm.final.dirty ? setpointForm.final.value : setpoint.final
          }
        />
        <DecoratedInput
          name="rate-setpoint"
          className={styles.setpointInput}
          classes={{ decoration: styles.setpointInputLabel }}
          decoration="Rate"
          type="number"
          min={0}
          onChange={updateSetpointInput("rate")}
          value={
            setpointForm.rate.dirty
              ? setpointForm.rate.value
              : setpoint.rate || "0"
          }
        />
        <Button
          className={classNames(
            styles.submitButton,
            isSetpointFieldDirty && styles.buttonEnabled
          )}
          onClick={postRampSetpoint}
          disabled={!isSetpointFieldDirty}
        >
          Send Setpoint <FontAwesomeIcon icon={faPaperPlane} />
        </Button>
      </Label>
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    width: 166,
  },
  content: {
    display: "grid",
    gridGap: 8,
    marginTop: 8,
  },
  setpointInput: {
    width: 150,
  },
  setpointInputLabel: {
    width: 60,
    height: 40,
    justifyContent: "center",
  },
  submitButton: {
    padding: 8,
    fontWeight: "bold",
    background: theme.color.neutral[6],
    color: theme.color.neutral[8],
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  buttonEnabled: {
    background: theme.color.support.cyan[1],
    color: theme.color.support.cyan[8],
    "&:hover": {
      background: theme.color.support.cyan[2],
      cursor: "pointer",
    },
  },
});

export default SetSetpoint;

import { createUseStyles } from "react-jss";

interface ActiveRunStatusProps {}

function ActiveRunStatus({}: ActiveRunStatusProps) {
  const styles = useStyles();
  return <div className={styles.root}></div>;
}

const useStyles = createUseStyles({
  root: {},
});
export default ActiveRunStatus;

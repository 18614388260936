import classNames from "classnames";
import { Fragment, memo, useState } from "react";
import { createUseStyles } from "react-jss";
import useInterval from "use-interval";
import { fetchDigitalIoState } from "../../server/digital-io";
import { theme } from "../../style/theme";
import { DioChannelState } from "../../types/digital-io";
import { ServiceEndpoint } from "../../types/network";
import Card from "../Common/Card";
import DigitalIoPin from "./DigitalIoPin";

export type DioChannelsPanelProps = ServiceEndpoint & { className?: string };

const PIN_COUNT = 32;
const PINS_PER_SECTION = 8;
const NUMBER_OF_GROUPS = Math.round(PIN_COUNT / PINS_PER_SECTION);
const pinGroups = [...new Array(NUMBER_OF_GROUPS)].map(
  (_, i) => PINS_PER_SECTION * i
);
const LAST_GROUP = pinGroups.slice(-1)[0] || 0;

function DioChannelsPanel({ ip, port, className }: DioChannelsPanelProps) {
  const styles = useStyles();

  const [dio, setDio] = useState<DioChannelState[]>([]);

  const refreshChannelState = async () => {
    setDio((await fetchDigitalIoState(ip, port)) || []);
  };
  useInterval(refreshChannelState, 1000);

  const handleTogglePin = async (pin: number, on: boolean) => {
    await fetch(`http://${ip}:${port}/digital-io/${pin}/${on ? "on" : "off"}`, {
      method: "POST",
    });
    setDio((await fetchDigitalIoState(ip, port)) || []);
  };

  if (!dio.length) return <Fragment />;

  return (
    <Card
      title="GPIO Channels"
      className={className}
      classes={{ content: styles.channelGrid, title: styles.title }}
    >
      {pinGroups.map((groupStart) => (
        <div key={groupStart} className={styles.pinSection}>
          <div className={styles.pinSectionContent}>
            {dio
              .slice(groupStart, groupStart + PINS_PER_SECTION)
              .map(({ pin, on }, i) => (
                <DigitalIoPin
                  key={pin}
                  pin={pin}
                  on={on}
                  onToggle={handleTogglePin}
                />
              ))}
          </div>
          {groupStart !== LAST_GROUP && (
            <div className={styles.divider} key={groupStart} />
          )}
        </div>
      ))}
    </Card>
  );
}

const useStyles = createUseStyles({
  channelGrid: {
    height: "max-content",
    fontWeight: "normal",
  },
  title: {},
  pinSection: {},
  pinSectionContent: {
    display: "grid",
    gridGap: theme.spacing[4],
    gridTemplateColumns: "repeat(4, max-content)",
  },
  divider: {
    gridColumn: "1 / 5",
    height: 10,
    borderBottom: `solid 2px ${theme.color.neutral[8]}`,
    margin: `${theme.spacing[3]}px 0`,
  },
});
export default memo(DioChannelsPanel);

import { memo } from "react";
import { createUseStyles } from "react-jss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../../style/theme";
import Label from "../Common/Label";
import classNames from "classnames";
import { DivElement } from "../../types/dom";

interface PidCoefficientsProps extends DivElement {
  kp: number;
  ki: number;
  kd: number;
}

function PidCoefficients({
  kp,
  ki,
  kd,
  className,
  ...rest
}: PidCoefficientsProps) {
  const styles = useStyles();

  return (
    <div className={classNames(className)} {...rest}>
      <Label
        value={
          <div>
            <FontAwesomeIcon icon={faSlidersH} /> PID Coefficients
          </div>
        }
        classes={{ content: styles.content, title: styles.title }}
      >
        <Label value="kp">{formatCoefficient(kp)}</Label>
        <Label value="ki">{formatCoefficient(ki)}</Label>
        <Label value="kd">{formatCoefficient(kd)}</Label>
      </Label>
      {/* <UpdateButtons
        className={classNames(styles.updateButtons, styles.hidden)}
      /> */}
    </div>
  );
}

const formatCoefficient = (value: number) => value.toFixed(2);

const useStyles = createUseStyles({
  root: {},
  title: {
    color: theme.color.primary[2],
    marginBottom: theme.spacing[0],
  },
  content: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: theme.spacing[4],
  },
  updateButtons: {
    marginTop: theme.spacing[1],
  },
  hidden: {
    // visibility: "hidden",
  },
});

export default memo(PidCoefficients);

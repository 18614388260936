import classNames from "classnames";
import React, { memo } from "react";
import { createUseStyles } from "react-jss";

import { DivElement } from "../../types/dom";

export interface LabelProps extends DivElement {
  value: React.ReactNode;
  classes?: Partial<{ root: string; title: string; content: string }>;
}

function Label(props: LabelProps) {
  const styles = useStyles();
  const { value, children, className, classes, ...rest } = props;
  return (
    <div
      className={classNames(styles.root, classes?.root, className)}
      {...rest}
    >
      <label className={classNames(styles.label, classes?.title)}>
        {value}
      </label>
      <div className={classNames(styles.content, classes?.content)}>
        {children}
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  root: {},
  label: {
    textTransform: "uppercase",
    fontSize: 12,
    letterSpacing: "0.05em",
    userSelect: "none",
    display: "inline-block",
    fontWeight: "normal",
  },
  content: {
    // display: "block"
  },
});
export default memo(Label);

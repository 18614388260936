import { Fragment, memo, useState } from "react";
import { createUseStyles } from "react-jss";
import useInterval from "use-interval";
import { fetchIgnitorsState } from "../../server/digital-io";
import { theme } from "../../style/theme";
import { IgnitorChannelState } from "../../types/digital-io";
import { ServiceEndpoint } from "../../types/network";
import Card from "../Common/Card";
import Label from "../Common/Label";
import DigitalIoPin from "./DigitalIoPin";

export type IgnitorsPanelProps = ServiceEndpoint & { className?: string };

function IgnitorsPanel({ ip, port, className }: IgnitorsPanelProps) {
  const styles = useStyles();

  const [ignitors, setIgnitors] = useState<IgnitorChannelState[]>([]);

  const refreshIgnitorState = async () => {
    setIgnitors((await fetchIgnitorsState(ip, port)) || []);
  };
  useInterval(refreshIgnitorState, 1000);

  const handleToggleIgnitor = async (pin: number, on: boolean) => {
    await fetch(`http://${ip}:${port}/ignitor/${pin}/${on ? "on" : "off"}`, {
      method: "POST",
    });
    setIgnitors((await fetchIgnitorsState(ip, port)) || []);
  };

  if (!ignitors.length) return <Fragment />;

  return (
    <Card
      className={className}
      title="Ignitors"
      classes={{ content: styles.channelGrid, title: styles.title }}
    >
      {ignitors?.map((ignitor) => {
        return (
          <DigitalIoPin
            key={ignitor.channel}
            pin={ignitor.channel}
            on={ignitor.on}
            onToggle={handleToggleIgnitor}
          />
        );
      })}
    </Card>
  );
}

const useStyles = createUseStyles({
  title: {},
  channelGrid: {
    display: "grid",
    gridGap: theme.spacing[4],
    "@media (max-width: 768px)": {
      gridTemplateColumns: "repeat(2, max-content)",
    },
  },
});
export default memo(IgnitorsPanel);

import classNames from "classnames";
import { memo } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";

import Label, { LabelProps } from "./Label";

export interface CardProps extends Omit<LabelProps, "ref" | "value" | "title"> {
  title?: React.ReactNode;
}

const Card = ({ children, className, title, classes, ...rest }: CardProps) => {
  const styles = useStyles();

  return (
    <Label
      value={title}
      className={classNames(className, styles.root)}
      classes={{
        title: classNames(styles.title, classes?.title),
        content: classNames(styles.content, classes?.content),
      }}
      {...rest}
    >
      {children}
    </Label>
  );
};

const useStyles = createUseStyles({
  root: {
    height: "max-content",
    fontWeight: "normal",
    marginTop: theme.spacing[0],
    borderRadius: theme.spacing[2],
    background: theme.color.neutral[10],
  },
  title: {
    display: "block",
    padding: theme.spacing[3],
    backgroundColor: theme.color.neutral[0],
    borderRadius: `${theme.spacing[2]}px ${theme.spacing[2]}px 0 0`,
    letterSpacing: "0.15em",
    color: theme.color.neutral[6],
    fontWeight: "bold",
  },
  content: {
    padding: theme.spacing[4],
  },
});

export default memo(Card);

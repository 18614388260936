import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Routes, Route } from "react-router-dom";
import { useDebounce } from "use-debounce";
import Configuration from "./components/Configuration/Configuration";
import Dashboard from "./components/Dashboard/Dashboard";
import Detectors from "./components/Detectors/Detectors";
import FlowControllers from "./components/FlowControllers";
import Ovens from "./components/Ovens";
import { theme } from "./style/theme";
import DigitalIoPanel from "./components/DigitalIo/DigitalIOPanel";
import Run from "./components/Run/Run";
import RunResults from "./components/Run/Results/RunResults";
import EclipseSidebar from "./components/EclipseSidebar";
import classNames from "classnames";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./components/Common/Button";
import Sidebar from "./components/Sidebar/Sidebar";
import OvenDetails from "./components/Oven/OvenDetails";

export const servicePorts = {
  oven: 8000,
  flow: 3000,
  dio: 8002,
  monitor: 9000,
};

function App() {
  if (window.location.hash) {
    // Overwrite local storage IP with hash value
    localStorage.setItem("ip", window.location.hash.replace(/#/, ""));
  }
  const [ip, setIp] = useState<string>(
    localStorage.getItem("ip") || "10.8.10.2"
  );
  const [debouncedIp] = useDebounce(ip, 250);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
  const styles = useStyles();

  const handleToggle = () => setSidebarOpen(!sidebarOpen);

  const handleSetIp = (newIp: string) => {
    setIp(newIp);
    localStorage.setItem("ip", newIp);
  };

  return (
    <div
      className={classNames(styles.root, !sidebarOpen && styles.menuCollapsed)}
    >
      {sidebarOpen ? (
        <EclipseSidebar
          ip={ip}
          onChangeIp={handleSetIp}
          className={styles.sidebar}
          onToggleSidebar={handleToggle}
        />
      ) : (
        <Sidebar className={styles.topbar}>
          <Button className={styles.toggleSidebarButton} onClick={handleToggle}>
            <FontAwesomeIcon icon={faBars} />
          </Button>
        </Sidebar>
      )}
      {sidebarOpen && <div className={styles.mainOverlay} />}
      <main className={styles.main}>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/ovens"
            element={<Ovens ip={debouncedIp} port={servicePorts.oven} />}
          />
          <Route
            path="/oven/:id"
            element={<OvenDetails ip={debouncedIp} port={servicePorts.oven} />}
          />
          <Route path="/results" element={<RunResults />} />
          <Route
            path="/flow"
            element={
              <FlowControllers ip={debouncedIp} port={servicePorts.flow} />
            }
          />
          <Route
            path="/dio"
            element={
              <DigitalIoPanel ip={debouncedIp} port={servicePorts.dio} />
            }
          />
          <Route
            path="/detectors"
            element={
              <Detectors
                ip={debouncedIp}
                ports={{ websocket: 7000, http: 8001 }}
              />
            }
          />
          <Route
            path="/run"
            element={<Run ip={debouncedIp} ports={{ method: 8003 }} />}
          />
          <Route path="/settings" element={<Configuration />} />
        </Routes>
      </main>
    </div>
  );
}

const useStyles = createUseStyles({
  "@global": {
    body: {
      margin: 0,
      fontFamily: "'Inter', sans-serif",
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
      backgroundColor: "#f0f4f8",
      color: "#102A43",
      boxSizing: "border-box",
      height: "100vh",
    },
  },
  root: {
    display: "grid",
    "@media (min-width: 800px)": {
      gridTemplateColumns: "max-content 1fr",
    },
    height: "100vh",
  },
  menuCollapsed: {
    display: "block",
  },
  mainOverlay: {
    display: "block",
    position: "fixed",
    zIndex: 1,
    "@media (max-width: 800px)": {
      display: "fixed",
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.3)",
    },
  },
  main: {
    overflowY: "scroll",
    padding: theme.spacing[3],
    "@media (max-width: 600px)": {
      padding: 0,
    },
    // "@media (min-width: 800px)": {
    //   paddingTop: 48,
    // },
  },
  "@media (max-width: 800px)": {
    sidebar: {
      position: "fixed",
    },
  },
  sidebar: {
    display: "inline-block",
    height: "100%",
    zIndex: 2,
  },
  topbar: {
    padding: theme.spacing[2],
    background: theme.color.neutral[8],
  },
  toggleSidebarButton: {},
});

export default App;

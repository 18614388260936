import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaucet } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { createUseStyles } from "react-jss";
import { DivElement } from "../../types/dom";
import { EpcState, MfcState } from "../../types/flow-control";
import Label from "../Common/Label";
import SetpointRamp from "../Oven/SetpointRamp";
import { theme } from "../../style/theme";
import { memo } from "react";
import { truncate } from "../../formatting";

const temperatureUnits = {
  epc: {
    setpoint: "psi",
    ramp: "psi/min",
  },
  mfc: { setpoint: "ml/min", ramp: "flow/min" },
};

interface EpcProps extends DivElement {
  className?: string;
  controller: EpcState | MfcState;
  kind: "epc" | "mfc";
}

function FlowController(props: EpcProps) {
  const {
    className,
    controller: { id, setpoint, actual },
    kind,
    ...rest
  } = props;

  const styles = useStyles();

  return (
    <div className={classNames(styles.root, className)} {...rest}>
      <div className={styles.title}>
        <span className={styles.icon}>
          <FontAwesomeIcon icon={faFaucet} />
        </span>
        <div>
          {kind} {id}
        </div>
        <div className={styles.status}>
          {/* <ControllerStatus
            // className={styles.status}
            cooling={state.state.cooling}
            application={state.state.application}
          /> */}
        </div>
      </div>
      <div className={styles.content}>
        <Label value={`Reading (${temperatureUnits[kind].setpoint})`}>
          {truncate(actual)}
        </Label>
        <SetpointRamp
          className={styles.ramp}
          initial={setpoint?.initial}
          final={setpoint?.final}
          rate={setpoint?.rate}
          updatedAt={setpoint?.startedAt}
          current={setpoint?.current || setpoint?.initial}
          units={temperatureUnits[kind]}
        />
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    background: theme.color.neutral[10],
    borderRadius: 4,
  },
  title: {
    padding: theme.spacing[3],
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.color.neutral[3],
    display: "grid",
    gridGap: theme.spacing[1],
    gridTemplateColumns: "max-content max-content 1fr",
    fontSize: 18,
    alignItems: "center",
    background: theme.color.neutral[8],
    borderRadius: `4px 4px 0px 0px`,
  },
  status: {
    justifySelf: "end",
    width: "max-content",
    display: "grid",
    gridTemplateColumns: "max-content 120px",
    gridGap: theme.spacing[4],
  },
  icon: {
    color: theme.color.neutral[2],
    width: 30,
    height: 30,
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "50%",
    background: theme.color.neutral[9],
  },
  sectionTitle: {
    color: theme.color.primary[2],
    marginBottom: theme.spacing[1],
  },
  content: {
    display: "grid",
    gridGap: theme.spacing[3],
    // gridTemplateColumns: "repeat(2, max-content)",
    padding: theme.spacing[4],
  },
  readings: {},
  ramp: {},
});

export default memo(FlowController);

import JSZip, { file } from "jszip";
import { markdownTable } from "markdown-table";
import { useEffect, useState } from "react";
import { FileContent } from "use-file-picker/dist/interfaces";
import Sample, { Point2D, SampleDataset } from "../types/sample";
import { SelectionMap } from "../components/Run/Results/SampleSelect";
import {
  findLowerBoundIndex,
  findMaximumIndex,
  findUpperBoundIndex,
  relativeStandardDeviation,
  standardDeviation,
} from "../math";
import { colors } from "../colors";
import { Data } from "./detector";

export type FileMap = {
  [key: string]: JSZip.JSZipObject;
};

export function useUncompressedZipFiles(
  file: FileContent | null
): JSZip | null {
  const [files, setFiles] = useState<JSZip | null>(null);

  useEffect(() => {
    const zip = new JSZip();
    async function uncompressZipFile(file: FileContent) {
      await zip.loadAsync(file.content);
      setFiles(zip);
    }

    if (file) uncompressZipFile(file);
  }, [file]);

  return files;
}

const parseDataFile = (content: string): Sample[] => {
  const result: Sample[] = [];

  const lines = content.split("\n").map((line) => line.split(",").map(Number));
  let pointerIndex = 0;
  let signalIndex = 0;
  while (pointerIndex < lines.length - 1) {
    let line = lines[pointerIndex];
    while (line && line[0] !== 1) {
      line = lines[++pointerIndex];
    }

    const signal: Data = {
      id: signalIndex,
      type: "scattergl",
      x: [],
      y: [],
      //@ts-ignore
      name: `Sample ${signalIndex}`,
      line: { color: colors[signalIndex % colors.length].value },
      hovertemplate: `Sample ${signalIndex}`,
    };

    let nextLine = lines[pointerIndex + 1];
    while (line && nextLine) {
      // Skip duplicate pairs
      if (nextLine[0] === line[0]) {
        pointerIndex++;
        line = lines[pointerIndex];
        nextLine = lines[pointerIndex + 1];
        continue;
      }
      signal.x.push(line[0] / (20 * 60));
      signal.y.push(line[1]);
      pointerIndex++;
      line = lines[pointerIndex];
      nextLine = lines[pointerIndex + 1];

      if (pointerIndex === lines.length - 1) break;
      if (line[0] === 1) break;
    }

    if (signal.x.length > 2)
      result.push({
        id: `Sample ${signalIndex}`,
        name: `Sample ${signalIndex}`,
        injectedAt: new Date(),
        method: { name: "Unknown" },
        stream: 0,
        results: {
          ovens: [],
          digitalIO: [],
          flows: [],
          detectors: [
            {
              id: 1,
              readings: signal,
            },
          ],
        },
      });
    signalIndex++;
  }

  return result;
};

export function useCsvDataFile(file: FileContent | null): Sample[] {
  const [data, setData] = useState<Sample[]>([]);

  useEffect(() => {
    async function parseCsvFile(file: FileContent) {
      const result = parseDataFile(file.content);
      setData(result);
    }

    if (file) parseCsvFile(file);
  }, [file]);

  return data;
}

export function useZipSampleNames(zip: JSZip | null) {
  const [names, setNames] = useState<string[] | null>(null);

  useEffect(() => {
    async function findSampleNames(zip: JSZip) {
      const sampleNames: { [name: string]: boolean } = {};
      Object.keys(zip.files).forEach((fileName) => {
        if (fileName.startsWith(".")) return;
        const sampleName = fileName.split("/")[0];
        sampleNames[sampleName] = true;
      });

      setNames(Object.keys(sampleNames));
    }

    if (zip !== null) findSampleNames(zip);
  }, [zip]);

  return names;
}

export function useSampleNames(samples: Sample[]) {
  const [names, setNames] = useState<string[] | null>(null);

  useEffect(() => {
    async function findSampleNames(samples: Sample[]) {
      setNames(samples.map((s) => s.name));
    }

    findSampleNames(samples);
  }, [samples]);

  return names;
}

export type RunInfo = {
  sampleName: string;
  injectedAt: Date;
  methodName: string;
  runLength: number;
  detectorNames: string[];
};

async function parseRunInfo(
  files: JSZip,
  sampleName: string
): Promise<RunInfo> {
  const parser = new DOMParser();
  const runInfoFile = files.file(`${sampleName}/runinfo.xml`);
  if (!runInfoFile)
    throw new Error(`Cannot parse file ${sampleName}/runinfo.xml`);
  const content = await runInfoFile.async("text");
  const parsedFile = parser.parseFromString(content, "text/xml");
  const detectorXMLElements = parsedFile
    .getElementsByTagName("Detectors")[0]
    .getElementsByTagName("Detector");
  const detectorNames: string[] = [];
  for (let i = 0; i < detectorXMLElements.length; i++) {
    detectorNames.push(
      detectorXMLElements[i].getElementsByTagName("Name")[0].firstChild
        ?.nodeValue!
    );
  }

  return {
    sampleName: parsedFile.getElementsByTagName("SampleName")[0].textContent!,
    injectedAt: new Date(
      parsedFile.getElementsByTagName("InjectionDateTime")[0].textContent!
    ),
    methodName:
      parsedFile.getElementsByTagName("AppliedMethod")[0].textContent!,
    runLength: Number(
      parsedFile.getElementsByTagName("RunLength")[0].textContent!
    ),
    detectorNames,
  };
}

async function parseDetectorData(
  files: JSZip,
  run: RunInfo,
  sampleIndex: number
): Promise<SampleDataset[]> {
  const detectorDatasets: SampleDataset[] = [];
  for (let name of run.detectorNames) {
    const detectorDataFile = files.file(`${run.sampleName}/${name}/0.sig`);
    if (!detectorDataFile) continue;
    const content = await detectorDataFile.async("text");
    const id = name.split(" ")[1];
    const lines = content.split("\n").slice(3);
    const x: number[] = new Array(lines.length);
    const y: number[] = new Array(lines.length);
    for (let i = 0; i < lines.length; i++) {
      const [tick, reading] = lines[i].split(" ").map(Number);
      x[i] = tick;
      y[i] = reading;
    }

    detectorDatasets.push({
      id,
      readings: {
        line: { color: colors[sampleIndex % colors.length].value },
        name: run.sampleName,
        hovertemplate: run.sampleName,
        type: "scattergl",
        x,
        y,
      },
    });
  }
  return detectorDatasets;
}

async function parseSample(
  files: JSZip | null,
  sampleName: string,
  sampleIndex: number
): Promise<Sample | null> {
  if (!files) return null;

  const run = await parseRunInfo(files, sampleName);
  const detectorData = await parseDetectorData(files, run, sampleIndex);

  return {
    id: run.sampleName,
    name: run.sampleName,
    injectedAt: run.injectedAt,
    method: {
      name: run.methodName,
    },
    stream: 0,
    results: {
      detectors: detectorData,
      ovens: [],
      digitalIO: [],
      flows: [],
    },
  };
}

export const useSampleSet = (
  files: JSZip | null,
  sampleSelection: SelectionMap
) => {
  const [samples, setSamples] = useState<Sample[]>([]);

  useEffect(() => {
    async function parseSampleFiles() {
      if (files === null) return;

      const nextSamples: Sample[] = [];
      const names = Object.keys(sampleSelection);
      for (let i = 0; i < names.length; i++) {
        const sampleName = names[i];
        if (!sampleSelection[sampleName]) continue;
        const cachedParsedSample = samples.find((s) => s.name === sampleName);
        if (cachedParsedSample) {
          nextSamples.push(cachedParsedSample);
          continue;
        }

        const sample = await parseSample(files, sampleName, i);
        if (sample) nextSamples.push(sample);
        else console.warn(`Could not parse sample ${sampleName}`);
      }

      // const ecv2etectorPeakWindows: { [detectorId: number]: [number, number][] } =
      //   {
      //     0: [
      //       [2.94, 3.06],
      //       [3.3, 3.45],
      //       [4.85, 5.15],
      //       [7.1, 7.4],
      //     ],
      //     1: [
      //       [2.82, 2.94],
      //       [3.06, 3.16],
      //       [4.0, 4.25],
      //       [5.3, 5.55],
      //     ],
      //   };

      const tiDetectorPeakWindows: {
        [detectorId: number]: [number, number][];
      } = {
        0: [
          [2.85, 2.93],
          [3.08, 3.18],
          [4.1, 4.3],
          [5.5, 5.7],
        ],
        1: [
          [2.65, 2.75],
          [2.92, 3.03],
          [4.04, 4.25],
          [5.55, 5.8],
        ],
      };

      const findRelativeRetentions = (
        signals: SampleDataset<Point2D>[],
        windows: [number, number][],
        peakPair: [number, number]
      ) => {
        const relativePeakRetentions = signals.map((signal) => {
          const firstPeakStartIndex = findLowerBoundIndex(
            signal.readings.x as number[],
            windows[peakPair[0]][0]
          );
          const firstPeakEndIndex = findUpperBoundIndex(
            signal.readings.x as number[],
            windows[peakPair[0]][1]
          );
          const lastPeakStartIndex = findLowerBoundIndex(
            signal.readings.x as number[],
            windows[peakPair[1]][0]
          );
          const lastPeakEndIndex = findUpperBoundIndex(
            signal.readings.x as number[],
            windows[peakPair[1]][1]
          );

          const firstPeakReadings = signal.readings.y!.slice(
            firstPeakStartIndex,
            firstPeakEndIndex
          ) as number[];
          const lastPeakReadings = signal.readings.y!.slice(
            lastPeakStartIndex,
            lastPeakEndIndex
          ) as number[];
          const firstPeakTimes = signal.readings.x!.slice(
            firstPeakStartIndex,
            firstPeakEndIndex
          ) as number[];
          const lastPeakTimes = signal.readings.x!.slice(
            lastPeakStartIndex,
            lastPeakEndIndex
          ) as number[];
          const firstPeakMaxIndex = findMaximumIndex(firstPeakReadings);
          const lastPeakMaxIndex = findMaximumIndex(lastPeakReadings);
          return (
            lastPeakTimes[lastPeakMaxIndex] - firstPeakTimes[firstPeakMaxIndex]
          );
        });

        return relativePeakRetentions;
      };

      const findAllRelativePeakDeltas = (
        signals: SampleDataset<Point2D>[],
        windows: [number, number][]
      ) => {
        const relativeRetentionJitters: [number, number, number, number][] = [];

        for (
          let initialPeakIndex = 0;
          initialPeakIndex < windows.length;
          initialPeakIndex++
        ) {
          for (
            let finalPeakIndex = initialPeakIndex + 1;
            finalPeakIndex < windows.length;
            finalPeakIndex++
          ) {
            const retentionDeltas = findRelativeRetentions(signals, windows, [
              initialPeakIndex,
              finalPeakIndex,
            ]);
            relativeRetentionJitters.push([
              initialPeakIndex,
              finalPeakIndex,
              standardDeviation(retentionDeltas),
              relativeStandardDeviation(retentionDeltas),
            ]);
          }
        }

        return relativeRetentionJitters;
      };

      // if (nextSamples.length) {
      //   const detectorCount = nextSamples[0].results.detectors.length;
      //   // detector name, first peak, second peak, std, rstd
      //   for (
      //     let detectorIndex = 0;
      //     detectorIndex < detectorCount;
      //     detectorIndex++
      //   ) {
      //     const results: [string, number, number, number, number][] = [];
      //     const signalSamples = nextSamples.map(
      //       (sample) => sample.results.detectors[detectorIndex]
      //     );
      //     const relativePeakDeltas = findAllRelativePeakDeltas(
      //       signalSamples,
      //       tiDetectorPeakWindows[detectorIndex]
      //     );
      //     relativePeakDeltas.forEach((result) => {
      //       const detectorId = nextSamples[0].results.detectors[detectorIndex]
      //         .id as string;
      //       results.push([detectorId, ...result]);
      //     });
      //     console.log(
      //       `FID ${nextSamples[0].results.detectors[detectorIndex].id}`
      //     );
      //     console.log(
      //       markdownTable([
      //         [
      //           "Initial Eluted Peak",
      //           "Final Eluted Peak",
      //           "STD of Retention Time Delta",
      //           "RSTD of Retention Time Delta",
      //         ],
      //         ...results.map((row) => [
      //           `${row[1] + 1}`,
      //           `${row[2] + 1}`,
      //           `${row[3].toFixed(4)}`,
      //           `${row[4].toFixed(2)}%`,
      //         ]),
      //       ])
      //     );
      //   }
      // }

      console.log({ nextSamples });
      setSamples(nextSamples);
    }

    parseSampleFiles();
  }, [files, sampleSelection]);

  console.log("returning", samples);
  return samples;
};

import { createUseStyles } from "react-jss";
import { useCurrentRun } from "../../hooks/run";
import ActiveRunStatus from "./ActiveRunStatus";
import Method from "./Method";

interface RunProps {
  ip: string;
  ports: { method: number };
}

function Run({ ip, ports }: RunProps) {
  const styles = useStyles();
  const run = useCurrentRun(ip, ports.method);
  return (
    <div className={styles.root}>
      <ActiveRunStatus />
      <Method ip={ip} ports={ports} />
    </div>
  );
}

const useStyles = createUseStyles({
  root: {},
});
export default Run;

import { Run, Method } from "../types/method";

export async function fetchRunStatus(
  ip: string,
  port: number
): Promise<Run | null> {
  const response = await fetch(`http://${ip}:${port}/run/status`);
  if (!response.ok) return null;

  return response.json();
}

export async function fetchLoadedMethod(
  ip: string,
  port: number
): Promise<Method | null> {
  const response = await fetch(`http://${ip}:${port}/method`);
  if (!response.ok) return null;

  return response.json();
}

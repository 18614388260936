import { ChangeEvent, KeyboardEvent, useState } from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { truncate } from "../../../formatting";
import { FormField } from "../../../types/dom";
import { ServiceEndpoint } from "../../../types/network";
import { PidLoopState } from "../../../types/oven";
import DecoratedInput from "../../Common/DecoratedInput";
import Label from "../../Common/Label";

type PidGainsForm = {
  kp: FormField;
  ki: FormField;
  kd: FormField;
};
type GainField = keyof PidGainsForm;

export interface SetPidGainsProps
  extends ServiceEndpoint,
    Omit<PidLoopState, "output"> {}

function SetPidGains({ ip, port, coefficients }: SetPidGainsProps) {
  const styles = useStyles();
  const { id } = useParams();
  const [pidGainsForm, setGainsForm] = useState<PidGainsForm>({
    kp: {
      value: String(truncate(coefficients.kp, 2)),
      dirty: false,
    },
    ki: {
      value: String(truncate(coefficients.ki, 2)),
      dirty: false,
    },
    kd: {
      value: String(truncate(coefficients.kd, 2)),
      dirty: false,
    },
  });

  const updateGainInputField =
    (field: GainField) => (event: ChangeEvent<HTMLInputElement>) => {
      setGainsForm({
        ...pidGainsForm,
        [field]: {
          value: event.target.value,
          dirty: true,
        },
      });
    };

  const postNewGainParameter = (field: GainField) => (event: KeyboardEvent) => {
    if (event.key !== "Enter") return;
    console.log(`http://${ip}:${port}/oven/${id}/pid/coil/${field}`);
    console.log(
      JSON.stringify({ value: Number(pidGainsForm[field].value) }, null, 2)
    );

    fetch(`http://${ip}:${port}/oven/${id}/pid/coil/${field}`, {
      method: "POST",
      body: JSON.stringify({
        value: Number(pidGainsForm[field].value),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    setGainsForm({
      ...pidGainsForm,
      [field]: { ...pidGainsForm[field], dirty: false },
    });
  };

  return (
    <div className={styles.root}>
      <Label classes={{ content: styles.pidGains }} value="PID Gains">
        <DecoratedInput
          name="kp"
          className={styles.pidGainInput}
          classes={{ decoration: styles.pidGainLabel }}
          decoration="KP"
          type="number"
          min={0}
          onChange={updateGainInputField("kp")}
          onKeyDown={postNewGainParameter("kp")}
          value={
            pidGainsForm.kp.dirty
              ? pidGainsForm.kp.value
              : String(coefficients.kp)
          }
        />
        <DecoratedInput
          name="ki"
          className={styles.pidGainInput}
          classes={{ decoration: styles.pidGainLabel }}
          decoration="KI"
          type="number"
          min={0}
          onChange={updateGainInputField("ki")}
          onKeyDown={postNewGainParameter("ki")}
          value={
            pidGainsForm.ki.dirty
              ? pidGainsForm.ki.value
              : String(coefficients.ki)
          }
        />
        <DecoratedInput
          name="kd"
          className={styles.pidGainInput}
          classes={{ decoration: styles.pidGainLabel }}
          decoration="KD"
          type="number"
          min={0}
          onChange={updateGainInputField("kd")}
          onKeyDown={postNewGainParameter("kd")}
          value={
            pidGainsForm.kd.dirty
              ? pidGainsForm.kd.value
              : String(coefficients.kd)
          }
        />
      </Label>
    </div>
  );
}

const useStyles = createUseStyles({
  root: {},
  pidGains: {
    display: "grid",
    gridGap: 8,
    marginTop: 8,
  },
  pidGainInput: {
    width: 100,
  },
  pidGainLabel: {
    width: 40,
    height: 40,
    justifyContent: "center",
  },
});

export default SetPidGains;

import { faRotate, faThermometerHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Datum } from "plotly.js";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import { useResizeDetector } from "react-resize-detector";
import { useParams } from "react-router-dom";
import useInterval from "use-interval";
import { Data } from "../../hooks/detector";
import { fetchOvens } from "../../server/ovens";
import { theme } from "../../style/theme";
import { ServiceEndpoint } from "../../types/network";
import { BackplaneChannel, OvenState } from "../../types/oven";
import { Viewport } from "../../types/plot";
import Button from "../Common/Button";
import Plot, { Dataset } from "../Common/Plot";
import SetPidGains from "./Forms/SetPidGains";
import SetSetpoint from "./Forms/SetSetpoint";

type OvenDetailsState = {
  ovenSnapshot: OvenState | null;
  epoch: number;
  datasets: {
    setpoint: Dataset & { x: number[]; y: number[] };
    actual: Dataset & { x: number[]; y: number[] };
    block: Dataset & { x: number[]; y: number[] };
    power: Dataset & { x: number[]; y: number[] };
  };
};

function OvenDetails({ ip, port }: ServiceEndpoint) {
  const { id } = useParams();
  const [state, setOvenState] = useState<OvenDetailsState>({
    ovenSnapshot: null,
    epoch: Date.now(),
    datasets: {
      setpoint: { name: "setpoint", type: "scattergl", x: [], y: [] },
      actual: { name: "actual", type: "scattergl", x: [], y: [] },
      block: { name: "block", type: "scattergl", x: [], y: [] },
      power: { name: "power", type: "scattergl", x: [], y: [] },
    },
  });
  const [viewport, setViewport] = useState<Viewport>({
    x: undefined,
    y: undefined,
  });
  const { width, ref } = useResizeDetector();
  const styles = useStyles();

  const updateOven = async () => {
    const parsedId = parseInt(id || "");
    if (isNaN(parsedId) || parsedId < 0 || parsedId > 15) return;
    const [ovenSnapshot] = await fetchOvens(ip, port, [
      parsedId as BackplaneChannel,
    ]);
    if (!ovenSnapshot) return;
    const dt = (Date.now() - state.epoch) / (60 * 1000);

    setOvenState({
      ovenSnapshot,
      epoch: state.epoch,
      datasets: {
        setpoint: {
          ...state.datasets.setpoint,
          x: state.datasets.setpoint.x.concat(dt),
          y: state.datasets.setpoint.y.concat(
            ovenSnapshot.temperature.setpoint.current
          ),
        },
        actual: {
          ...state.datasets.actual,
          x: state.datasets.actual.x.concat(dt),
          y: state.datasets.actual.y.concat(ovenSnapshot.temperature.actual),
        },
        block: {
          ...state.datasets.block,
          x: state.datasets.block.x.concat(dt),
          y: state.datasets.block.y.concat(
            ovenSnapshot.temperature.block
              ? ovenSnapshot.temperature.block
              : ovenSnapshot.temperature.actual
          ),
        },
        power: {
          ...state.datasets.power,
          x: state.datasets.power.x.concat(dt),
          y: state.datasets.power.y.concat(ovenSnapshot.pid.coil.output),
        },
      },
    });
  };
  useInterval(updateOven, 250);

  const handleViewportChange = (viewport: Viewport) => {
    setViewport(viewport);
  };

  if (!state.ovenSnapshot) return <div />;

  const version =
    `v${state.ovenSnapshot.version.major}.` +
    `${state.ovenSnapshot.version.minor}.` +
    `${state.ovenSnapshot.version.patch}`;

  return (
    <div className={styles.root}>
      <div className={styles.title} title={version}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faThermometerHalf} />
        </div>
        <div
          className={styles.titleText}
        >{`Oven ${state.ovenSnapshot.id}`}</div>
        <div className={styles.titlePanel}>
          <Button className={styles.disableButton}>
            <FontAwesomeIcon icon={faRotate} /> Reset Oven
          </Button>
        </div>
      </div>
      <div className={styles.plotContainer} ref={ref}>
        <Plot
          data={[
            state.datasets.setpoint,
            state.datasets.actual,
            state.datasets.block,
          ]}
          onChangeViewport={handleViewportChange}
          revision={state.datasets.setpoint.x?.length || 0}
          width={width}
        />
      </div>
      <div className={styles.ovenConfiguration}>
        <SetPidGains
          ip={ip}
          port={port}
          coefficients={state.ovenSnapshot.pid.coil.coefficients}
        />
        <SetSetpoint
          ip={ip}
          port={port}
          setpoint={state.ovenSnapshot.temperature.setpoint}
        />
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  root: { display: "grid", gridGap: 16, padding: 16 },
  title: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr 1fr",
    width: "100%",
    fontSize: 20,
    letterSpacing: "0.03em",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.color.neutral[3],
    alignItems: "center",
  },
  titleText: {
    width: "max-content",
  },
  titlePanel: {
    display: "grid",
    gridAutoFlow: "column",
    justifyContent: "end",
  },
  icon: {
    color: theme.color.neutral[2],
    width: 18,
    height: 18,
    padding: 8,
    marginRight: 8,
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "50%",
    background: theme.color.neutral[8],
  },
  disableButton: {
    padding: 8,
    color: "rgb(251, 104, 104)",
    fontWeight: "bold",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "rgba(251, 104, 104, 0.1)",
    },
  },
  plotContainer: {},
  ovenConfiguration: {
    display: "grid",
    gridAutoFlow: "column",
    width: "max-content",
    gridGap: 64,
  },
});

export default OvenDetails;

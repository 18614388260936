import { Layout, PlotData } from "plotly.js";
import { Data } from "./hooks/detector";
import { Viewport } from "./types/plot";

export const mean = (values: number[]) =>
  values.reduce((sum, value) => value + sum, 0) / values.length;

export const standardDeviation = (values: number[]) => {
  const average = mean(values);
  return Math.sqrt(
    values.reduce((sum, value) => sum + (value - average) ** 2, 0) /
      values.length
  );
};

export const relativeStandardDeviation = (values: number[]) => {
  const std = standardDeviation(values);
  const avg = mean(values);
  return (100 * std) / avg;
};

export const findMaximumIndex = (values: number[]) => {
  let maxIndex = 0;
  let maxValue = 0;
  values.forEach((datum, i) => {
    if (datum! > maxValue) {
      maxValue = datum as number;
      maxIndex = i;
    }
  });

  return maxIndex;
};

export const findLowerBoundIndex = (
  values: number[],
  bound: number
): number => {
  let startIndex = values.findIndex((d) => d >= bound);
  if (startIndex === -1) startIndex = 0;

  return startIndex;
};

export const findUpperBoundIndex = (
  values: number[],
  bound: number
): number => {
  let endIndex = values.findIndex((d) => d >= bound);
  if (endIndex === -1) endIndex = values.length - 1;

  return endIndex;
};

export const calculateNoise = (data: Data) => {
  if (data.y.length < 2) return 0;

  return (
    data.y.reduce((total, _, i) => {
      return total + Math.abs(data.y[i] - (data.y[i - 1] || data.y[i]));
    }, 0) / data.y.length
  );
};

export const decimateDataset = (
  dataset: Data,
  viewport: Viewport,
  maxPoints: number
): Data => {
  let dataSubset = dataset;
  if (viewport.x && dataset.x && dataset.y) {
    let startIndex = dataset.x.findIndex((d) => d >= viewport.x![0]);

    if (startIndex === -1) startIndex = 0;
    let endIndex = dataset.x.findIndex((d) => d >= viewport.x![1]);
    if (endIndex === -1) endIndex = dataset.x.length;

    dataSubset = {
      ...dataset,
      x: dataset.x.slice(startIndex, endIndex),
      y: dataset.y.slice(startIndex, endIndex),
    };
  }

  if (!dataSubset.x || !dataSubset.y || dataSubset.x.length < maxPoints)
    return dataSubset;
  const pointDelta = Math.floor(dataSubset.x.length / maxPoints);
  const x: number[] = new Array(maxPoints);
  const y: number[] = new Array(maxPoints);
  for (let i = 0; i < dataSubset.x.length; i += pointDelta) {
    x.push(dataSubset.x[i] as number);
    y.push(dataSubset.y[i] as number);
  }

  return { ...dataset, x, y };
};

export const decimateDatasets = (
  data: Partial<PlotData>[],
  viewport: Viewport,
  maxPoints = 0
): Partial<PlotData>[] => {
  if (maxPoints === 0) return data;
  if (data.every(hasFewerPointsThan(maxPoints))) return data;
  //@ts-ignore
  const datasets = data.map((d) => decimateDataset(d, viewport, maxPoints));

  const retentionTimes: number[] = [];
  datasets.forEach((dataset) => {
    let maxIndex = 0;
    let maxValue = 0;
    dataset.y?.forEach((datum, i) => {
      if (datum! > maxValue) {
        maxValue = datum as number;
        maxIndex = i;
      }
    });
    retentionTimes.push(dataset.x![maxIndex] as number);
  });

  return datasets;
};

export const hasFewerPointsThan =
  (points: number) => (dataset: Partial<PlotData>) =>
    !dataset || !dataset.x || dataset.x?.length <= points;

import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useFilePicker } from "use-file-picker";
import { FileContent } from "use-file-picker/dist/interfaces";
import {
  useCsvDataFile,
  useSampleNames,
  useUncompressedZipFiles,
  useZipSampleNames,
} from "../../../hooks/samples";

import { theme } from "../../../style/theme";
import Button from "../../Common/Button";
import SampleDetails from "./SampleDetails";
import SampleSelect, { SelectionMap } from "./SampleSelect";

interface RunResultsProps {}

function RunResults({}: RunResultsProps) {
  const styles = useStyles();
  const [openZipSelector, zipResult] = useFilePicker({
    accept: ".zip",
    multiple: false,
    readAs: "ArrayBuffer",
  });
  const [openCsvSelector, csvResult] = useFilePicker({
    accept: ".csv",
    multiple: false,
    readAs: "Text",
  });
  const files = useUncompressedZipFiles(
    (zipResult.filesContent.length &&
      (zipResult.filesContent[0] as FileContent)) ||
      null
  );
  const csvData = useCsvDataFile(
    (csvResult.filesContent.length &&
      (csvResult.filesContent[0] as FileContent)) ||
      null
  );
  const sampleNames = useZipSampleNames(files);
  // const sampleNames = useSampleNames(csvData);
  const [selectedSamples, setSelectedSamples] = useState<SelectionMap>({});
  return (
    <div className={styles.root}>
      <div className={styles.controlBar}>
        <Button className={styles.openFolderButton} onClick={openZipSelector}>
          <FontAwesomeIcon icon={faFolder} /> Open Sampleset
        </Button>
        <Button className={styles.openFolderButton} onClick={openCsvSelector}>
          <FontAwesomeIcon icon={faFolder} /> Open CSV File
        </Button>
      </div>
      <div className={styles.sampleSetContainer}>
        <SampleSelect
          // sampleIds={sampleNames || csvData.map((sample) => sample.name)}
          sampleIds={sampleNames || []}
          onChange={setSelectedSamples}
        />
        {(files || csvData) && (
          <SampleDetails
            selectedSamples={selectedSamples}
            files={files}
            samples={csvData}
            title={zipResult.filesContent[0]?.name}
          />
        )}
        {/* {(files || csvData) && (
          <SampleDetails
            selectedSamples={selectedSamples}
            files={files}
            samples={csvData}
            title={zipResult.filesContent[0]?.name}
          />
        )} */}
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    width: 1100,
  },
  controlBar: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: theme.spacing[3],
    marginBottom: theme.spacing[3],
  },
  openFolderButton: {
    background: theme.color.primary[4],
    padding: theme.spacing[2],
    textTransform: "uppercase",
    color: theme.color.primary[8],
  },
  sampleSetContainer: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: theme.spacing[4],
  },
});
export default memo(RunResults);

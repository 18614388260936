import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThermometerHalf, faRuler } from "@fortawesome/free-solid-svg-icons";
import { memo } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";

import { OvenState } from "../../types/oven";
import Label from "../Common/Label";
import ControllerStatus from "./ControllerStatus";
import FirmwareVersion from "./FirmwareVersion";
import PidCoefficients from "./PidCoefficients";
import SetpointRamp from "./SetpointRamp";
import { DivElement } from "../../types/dom";
import classNames from "classnames";
import Link from "../Common/Link";

const temperatureUnits = { setpoint: "C", ramp: "°C/min" };

interface OvenProps extends DivElement {
  state: OvenState;
}

function Oven(props: OvenProps) {
  const styles = useStyles();
  const { state, className, ...rest } = props;
  const {
    version,
    pid: {
      coil: { coefficients, output },
    },
    temperature: { setpoint, actual, block },
    maxBlockTempC,
  } = state;

  return (
    <Link to={`/oven/${state.id}`}>
      <div className={classNames(styles.root, className)} {...rest}>
        <div className={styles.title}>
          <div className={styles.ovenTitle}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faThermometerHalf} />
            </div>
            <div>Oven {state.id}</div>
          </div>
          <div className={styles.status}>
            <FirmwareVersion
              major={version.major}
              minor={version.minor}
              patch={version.patch}
            />
            <ControllerStatus
              cooling={state.state.cooling}
              application={state.state.application}
            />
          </div>
        </div>
        <div className={styles.content}>
          <Label
            value={
              <div>
                <FontAwesomeIcon icon={faRuler} /> Readings
              </div>
            }
            classes={{ content: styles.readings, title: styles.sectionTitle }}
            className={classNames(styles.section, styles.bordered)}
          >
            <Label value="Temp (°C)" className={styles.bold}>
              {actual.toFixed(2)}
            </Label>
            <Label value="Power">{(100 * output).toFixed(0)}%</Label>
            {block && block > 0 && (
              <Label value="Block Temp (°C)">{block.toFixed(2)}</Label>
            )}
            {maxBlockTempC && maxBlockTempC > 0 && (
              <Label value="Max Block Temp (°C)">
                {maxBlockTempC.toFixed(2)}
              </Label>
            )}
          </Label>
          <SetpointRamp
            className={classNames(styles.section, styles.bordered)}
            initial={setpoint.initial}
            final={setpoint.final}
            rate={setpoint.rate}
            updatedAt={setpoint.updatedAt}
            current={setpoint.current}
            units={temperatureUnits}
          />
          <PidCoefficients
            className={classNames(styles.section)}
            kp={coefficients.kp}
            ki={coefficients.ki}
            kd={coefficients.kd}
          />
        </div>
      </div>
    </Link>
  );
}

const useStyles = createUseStyles({
  root: {
    background: theme.color.neutral[10],
    borderRadius: 4,
  },
  title: {
    padding: theme.spacing[3],
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.color.neutral[3],
    display: "grid",
    gridGap: theme.spacing[6],
    gridTemplateColumns: "max-content 1fr",
    fontSize: 18,
    alignItems: "center",
    justifyItems: "center",
    background: theme.color.neutral[8],
    borderRadius: `4px 4px 0px 0px`,
  },
  ovenTitle: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: theme.spacing[1],
    justifyItems: "center",
    alignItems: "center",
  },
  status: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: theme.spacing[3],
    alignItems: "end",
    justifyItems: "center",
    justifySelf: "end",
  },
  icon: {
    color: theme.color.neutral[2],
    width: 30,
    height: 30,
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "50%",
    background: theme.color.neutral[9],
  },
  sectionTitle: {
    color: theme.color.primary[2],
    marginBottom: theme.spacing[0],
  },
  content: {
    display: "grid",
    gridGap: theme.spacing[1],
    padding: `${theme.spacing[0]}px 0`,
  },
  readings: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: theme.spacing[3],
  },
  section: {
    padding: `${theme.spacing[1]}px ${theme.spacing[3]}px`,
  },
  bordered: {
    borderBottom: `solid 2px ${theme.color.neutral[9]}`,
  },
  bold: {
    fontWeight: "bold",
  },
});

export default memo(Oven);

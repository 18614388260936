import classNames from "classnames";
import { memo } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";

import { DivElement } from "../../types/dom";
import { OvenApplicationState, OvenCoolState } from "../../types/oven";
import Label from "../Common/Label";

const makeStatusMessage = (
  appState: OvenApplicationState,
  coolState: OvenCoolState
): string => {
  if (coolState !== "NotCooling") return "Purging Heat";
  if (appState !== "PidMonitor") return "Zeroing Door";
  return "Thermal Control";
};

interface ControllerStatusProps extends DivElement {
  application: OvenApplicationState;
  cooling: OvenCoolState;
}

function ControllerStatus(props: ControllerStatusProps) {
  const styles = useStyles();
  const { application, cooling, ref, className, ...rest } = props;

  return (
    <Label
      value="Oven State"
      className={classNames(styles.root, className)}
      classes={{ title: styles.title, content: styles.content }}
      {...rest}
    >
      {makeStatusMessage(application, cooling)}
    </Label>
  );
}

const useStyles = createUseStyles({
  root: {},
  title: {
    fontSize: 10,
  },
  content: {
    fontSize: 12,
    fontWeight: "normal",
  },
});

export default memo(ControllerStatus);

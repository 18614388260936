import classNames from "classnames";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";
import { InputElement } from "../../types/dom";
import Input from "./Input";

export interface DecoratedInputProps extends InputElement {
  decoration: React.ReactNode;
  onRevertChanges?: () => void;
  classes?: Partial<{
    decoration: string;
    input: string;
  }>;
}

function DecoratedInput(props: DecoratedInputProps) {
  const styles = useStyles();

  const { decoration, className, classes, onRevertChanges, ...rest } = props;

  return (
    <div className={classNames(styles.root, className)}>
      <div className={classNames(styles.decoration, classes?.decoration)}>
        {decoration}
      </div>
      <Input className={classNames(styles.input, classes?.input)} {...rest} />
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
  },
  input: {
    borderRadius: `0 ${theme.spacing[0]}px ${theme.spacing[0]}px 0`,
    width: "100%",
  },
  decoration: {
    display: "grid",
    // justifyContent: "center",
    alignContent: "center",
    background: theme.color.neutral[0],
    color: theme.color.neutral[7],
    fontWeight: "bold",
    borderRadius: `${theme.spacing[0]}px 0 0 ${theme.spacing[0]}px`,
  },
});

export default DecoratedInput;

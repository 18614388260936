import { memo } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";
import { DivElement } from "../../types/dom";

interface SidebarLinksProps extends DivElement {}

function SidebarLinks(props: SidebarLinksProps) {
  const styles = useStyles();
  const { children, ...rest } = props;

  return (
    <div className={styles.root} {...rest}>
      {children}
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    display: "grid",
    gridGap: theme.spacing[3],
  },
});
export default memo(SidebarLinks);

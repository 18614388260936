import {
  faTachometerAlt,
  faVial,
  faThermometerHalf,
  faToggleOn,
  faFaucet,
  faChartLine,
  faClipboardList,
  faCog,
  faChevronLeft,
  faChevronRight,
  faGripLinesVertical,
  faBars,
  faArrowsLeftRightToLine,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createUseStyles } from "react-jss";
import { memo, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { theme } from "../style/theme";
import Input from "./Common/Input";
import Label from "./Common/Label";
import LinkGroup from "./Sidebar/LinkGroup";
import Sidebar from "./Sidebar/Sidebar";
import SidebarLink from "./Sidebar/SidebarLink";
import SidebarLinks from "./Sidebar/SidebarLinks";
import Topbar from "./Sidebar/Topbar";
import { useLocation } from "react-router-dom";
import Button from "./Common/Button";
import { DivElement } from "../types/dom";
import classNames from "classnames";

const pkg = require("../../package.json");

export type EclipseSidebarProps = DivElement & {
  ip: string;
  onChangeIp: (value: string) => void;
  onToggleSidebar?: () => void;
};

const EclipseSidebar = ({
  ip,
  onChangeIp,
  className,
  onToggleSidebar,
  ...rest
}: EclipseSidebarProps) => {
  const styles = useStyles();
  const [newIp, setNewIp] = useState<string>(ip);
  const [debouncedIp] = useDebounce(newIp, 250);
  const { pathname } = useLocation();

  const handleSetIp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewIp(event.target.value);
  };

  useEffect(() => {
    onChangeIp(debouncedIp);
  }, [debouncedIp]);

  return (
    <Sidebar className={classNames(styles.root, className)} {...rest}>
      <Topbar className={styles.topbar}>
        <Label value="Instrument IP">
          <Input
            value={newIp}
            className={styles.ipInput}
            onChange={handleSetIp}
          />
        </Label>
        <Button
          onClick={onToggleSidebar}
          className={styles.toggleSidebarButton}
        >
          <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
        </Button>
      </Topbar>
      <SidebarLinks>
        <LinkGroup title="Monitoring">
          <SidebarLink to="/dashboard" active={pathname === "/dashboard"}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faTachometerAlt} />
            </span>
            <span>Dashboard</span>
          </SidebarLink>
          <SidebarLink to="/results" active={pathname === "/results"}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faVial} />
            </span>
            <span>Run Results</span>
          </SidebarLink>
        </LinkGroup>
        <LinkGroup title="Instrument Components">
          <SidebarLink to="/ovens" active={pathname === "/ovens"}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faThermometerHalf} />
            </span>
            <span>Ovens</span>
          </SidebarLink>
          <SidebarLink to="/dio" active={pathname === "/dio"}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faToggleOn} />
            </span>
            <span>Digital I/O</span>
          </SidebarLink>
          <SidebarLink to="/flow" active={pathname === "/flow"}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faFaucet} />
            </span>
            <span>Flow Controllers</span>
          </SidebarLink>
          <SidebarLink to="/detectors" active={pathname === "/detectors"}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faChartLine} />
            </span>
            <span>Detectors</span>
          </SidebarLink>
        </LinkGroup>
        <LinkGroup title="Configuration">
          <SidebarLink to="/run" active={pathname === "/run"}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faClipboardList} />
            </span>
            <span>Sampling Method</span>
          </SidebarLink>
          <SidebarLink to="/settings" active={pathname === "/settings"}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faCog} />
            </span>
            <span>Dashboard Settings</span>
          </SidebarLink>
        </LinkGroup>
      </SidebarLinks>
      <div className={styles.version}>ECV2 Dashboard {pkg.version}</div>
    </Sidebar>
  );
};

const useStyles = createUseStyles({
  root: {
    width: 250,
    backgroundColor: "white",
    borderRight: `solid 1px ${theme.color.neutral[7]}`,
  },
  icon: {
    color: theme.color.neutral[2],
    marginRight: theme.spacing[2],
    display: "inline-grid",
    justifyContent: "center",
    alignContent: "center",
    width: 16,
  },
  topbar: {
    background: theme.color.neutral[9],
    padding: theme.spacing[4],
    marginBottom: theme.spacing[4],
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    justifyItems: "end",
    alignItems: "center",
  },
  ipInput: {
    marginTop: theme.spacing[1],
    width: 100,
  },
  toggleSidebarButton: {
    width: 40,
    height: 40,
  },
  version: {
    fontSize: "10px",
    padding: theme.spacing[3],
  },
});

export default memo(EclipseSidebar);

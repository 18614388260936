import classNames from "classnames";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";
import { ButtonElement } from "../../types/dom";

interface ButtonProps extends ButtonElement {}

function Button(props: ButtonProps) {
  const styles = useStyles();
  const { children, className, ...rest } = props;
  return (
    <button className={classNames(styles.root, className)} {...rest}>
      {children}
    </button>
  );
}

const useStyles = createUseStyles({
  root: {
    border: "none",
    padding: theme.spacing[0],
    borderRadius: theme.spacing[0],
    background: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
});
export default Button;

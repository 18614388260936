import { isIP } from "is-ip";
import { useEffect, useState } from "react";

export function useWebsocket(ip: string, port: number): WebSocket | undefined {
  const [websocket, setWebsocket] = useState<WebSocket | undefined>();

  useEffect(() => {
    function updateWebsocket() {
      if (!isIP(ip)) return;

      setWebsocket(new WebSocket(`ws://${ip}:${port}`));
    }

    function closeWebsocket() {
      websocket && websocket.close();
    }

    updateWebsocket();

    return closeWebsocket;
  }, [ip, port]);

  return websocket;
}

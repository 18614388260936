import { EpcState, MfcState } from "../types/flow-control";

export async function fetchEpcs(ip: string, port: number): Promise<EpcState[]> {
  const epcs = await fetch(`http://${ip}:${port}/epcs`);
  if (!epcs.ok) return [];

  return epcs.json();
}

export async function fetchMfcs(ip: string, port: number): Promise<MfcState[]> {
  const epcs = await fetch(`http://${ip}:${port}/mfcs`);
  if (!epcs.ok) return [];

  return epcs.json();
}

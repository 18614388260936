import classNames from "classnames";
import { memo, useState } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";
import { DivElement } from "../../types/dom";

const toggle = {
  width: 60,
  thumb: { diameter: 25 },
  margin: 4,
  borderRadius: 20,
};

interface SwitchProps extends DivElement {
  className?: string;
  on?: boolean;
  onToggle?: (on: boolean) => void;
}

function Switch(props: SwitchProps) {
  const { className, on, onToggle, ...rest } = props;

  const styles = useStyles();

  const [_on, setOn] = useState<boolean>(Boolean(on));
  const value = on !== undefined ? on : _on;

  const handleToggle = () => (onToggle && onToggle(!value)) || setOn(!value);

  return (
    <div
      className={classNames(styles.root, className)}
      onClick={handleToggle}
      {...rest}
    >
      <div
        className={classNames(
          styles.thumb,
          (value && styles.active) || styles.inactive
        )}
      />
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    borderRadius: toggle.borderRadius,
    width: toggle.width,
    height: toggle.thumb.diameter + 2 * toggle.margin,
    background: theme.color.neutral[9],
    "&:hover": {
      cursor: "pointer",
    },
  },
  active: {
    background: theme.color.primary[5],
    left: `${
      toggle.width - toggle.margin - toggle.thumb.diameter
    }px !important`,
    transition: "left 0.1s, bacground 0.3s",
    filter: `drop-shadow(0px 0px 8px rgba(64, 152, 215, 0.8))`,
  },
  inactive: {
    background: theme.color.neutral[7],
    transition: "left 0.1s, background 0.3s",
    filter: `drop-shadow(0px 0px 2px rgba(188, 204, 220, 1))`,
  },
  thumb: {
    width: toggle.thumb.diameter,
    height: toggle.thumb.diameter,
    borderRadius: toggle.borderRadius,
    position: "relative",
    top: toggle.margin,
    left: toggle.margin,
    transition: "left 0.05s",
  },
});
export default memo(Switch);

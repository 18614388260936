import { createUseStyles } from "react-jss";

interface ConfigurationProps {}

function Configuration({}: ConfigurationProps) {
  const styles = useStyles();
  return <div className={styles.root}>Configuration Component</div>;
}

const useStyles = createUseStyles({
  root: {},
});
export default Configuration;

import classNames from "classnames";
import { memo } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";
import { DivElement } from "../../types/dom";

interface SidebarProps extends Omit<DivElement, "ref"> {}

function Sidebar(props: SidebarProps) {
  const styles = useStyles();
  const { children, className, ...rest } = props;

  return (
    <div className={classNames(styles.root, className)} {...rest}>
      {children}
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    background: theme.color.neutral[10],
    borderRight: `solid 2px ${theme.color.neutral[8]}`,
  },
});
export default memo(Sidebar);

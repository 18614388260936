import { DioChannelState, IgnitorChannelState } from "../types/digital-io";

export async function fetchDigitalIoState(
  ip: string,
  port: number
): Promise<DioChannelState[] | undefined> {
  const response = await fetch(`http://${ip}:${port}/digital-io`);
  if (!response.ok) return undefined;

  return response.json();
}

export async function fetchIgnitorsState(
  ip: string,
  port: number
): Promise<IgnitorChannelState[] | undefined> {
  const response = await fetch(`http://${ip}:${port}/ignitors`);
  if (!response.ok) return undefined;

  return response.json();
}

import { isIP } from "is-ip";
import { useEffect, useState } from "react";
import useInterval from "use-interval";
import { calculateNoise, decimateDataset, standardDeviation } from "../math";
import { Viewport } from "../types/plot";

export type Data = {
  id: number;
  x: number[];
  y: number[];
  type: "scattergl";
};

type SetData = React.Dispatch<React.SetStateAction<Data[]>>;
type DataMessage = { id: number; data: [number, number][] };
export function useDetectorData(
  websocket: WebSocket | undefined,
  detectors: DetectorState[]
): [Data[], SetData] {
  const [datasets, setDatasets] = useState<Data[]>(
    detectors.map(({ id }) => ({
      id,
      type: "scattergl",
      x: [],
      y: [],
    }))
  );

  useEffect(() => {
    if (detectors.length === datasets.length) return;
    setDatasets(
      detectors.map(({ id }) => ({
        id,
        type: "scattergl",
        x: [],
        y: [],
      }))
    );
  }, [detectors]);

  const handleMessage = (message: MessageEvent) => {
    const { id, data } = JSON.parse(message.data) as DataMessage;
    const newXs: number[] = [];
    const newYs: number[] = [];
    data.slice(0, -1).forEach((datum) => {
      newXs.push(datum[0] / (20 * 60));
      newYs.push(datum[1]);
    });

    setDatasets((prevDatasets: Data[]) => {
      return prevDatasets.map((dataset) => {
        if (dataset.id === id) {
          return {
            id,
            type: "scattergl",
            x: dataset.x.concat(newXs),
            y: dataset.y.concat(newYs),
          };
        }
        return dataset;
      });
    });
  };

  useEffect(() => {
    if (!websocket) return;
    websocket.addEventListener("message", handleMessage);

    return () => {
      websocket.removeEventListener("message", handleMessage);
    };
  }, [websocket]);

  return [datasets, setDatasets];
}

export function useDetectorIds(ip: string, port: number): number[] {
  const [ids, setIds] = useState<number[]>([]);

  useEffect(() => {
    const fetchIds = async () => {
      if (!isIP(ip)) return;
      const response = await fetch(`http://${ip}:${port}/detectors`);
      if (!response.ok) return;
      const { ids } = await response.json();
      setIds(ids);
    };
    fetchIds();
  }, [ip, port]);

  return ids;
}

export type DetectorState = {
  id: number;
  kind: "FID" | "TCD" | "PDHID";
  rate: null;
  isRunning: boolean;
};

const fetchDetectorState = async (
  ip: string,
  port: number,
  id: number
): Promise<DetectorState | undefined> => {
  const response = await fetch(`http://${ip}:${port}/detector/${id}`);
  if (!response.ok) return undefined;
  return await response.json();
};

type SetDetectors = React.Dispatch<React.SetStateAction<DetectorState[]>>;
export function useDetectorState(
  ip: string,
  port: number
): [DetectorState[], SetDetectors] {
  const ids = useDetectorIds(ip, port);
  const [detectors, setDetectors] = useState<DetectorState[]>([]);

  const fetchAllDetectors = async () => {
    const states = await Promise.all(
      ids.map((id) => fetchDetectorState(ip, port, id))
    );
    setDetectors(
      states.filter((state) => state !== undefined) as DetectorState[]
    );
  };

  useInterval(fetchAllDetectors, 500, true);

  return [detectors, setDetectors];
}

export type DetectorStatistics = {
  noise: number;
  standardDeviation: number;
};

export const useDecimatedData = (
  data: Data,
  viewport: Viewport,
  points: number
): Data => {
  const [decimatedData, setDecimatedData] = useState<Data>(data);

  const updateDecimatedData = async () => {
    setDecimatedData(decimateDataset(data, viewport, points));
  };

  useEffect(() => {
    updateDecimatedData();
  }, [data]);

  return decimatedData;
};

export const useDetectorStatistics = (data: Data) => {
  const [statistics, setStatistics] = useState<DetectorStatistics>({
    noise: 0,
    standardDeviation: 0,
  });

  const updateStatistics = async () => {
    if (!data || data.x.length < 2) return;
    setStatistics({
      noise: calculateNoise(data),
      standardDeviation: standardDeviation(data.y),
    });
  };

  useEffect(() => {
    updateStatistics();
  }, [data]);

  return statistics;
};

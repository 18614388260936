import classNames from "classnames";
import { memo } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { theme } from "../../style/theme";

import { DivElement } from "../../types/dom";

interface SidebarLinkProps extends DivElement {
  to: string;
  active?: boolean;
}

function SidebarLink(props: SidebarLinkProps) {
  const styles = useStyles();
  const { to, children, active, className, ...rest } = props;

  return (
    <Link to={to} className={styles.anchor}>
      <div
        className={classNames(styles.root, active && styles.active, className)}
        {...rest}
      >
        {children}
      </div>
    </Link>
  );
}

const useStyles = createUseStyles({
  root: {
    paddingLeft: theme.spacing[4],
    display: "grid",
    alignItems: "center",
    gridAutoFlow: "column",
    justifyContent: "flex-start",
  },
  anchor: {
    height: 40,
    lineHeight: "40px",
    textDecoration: "none",
    color: "inherit",
    width: "100%",
  },
  active: {
    background: theme.color.neutral[9],
    borderRight: `solid 3px ${theme.color.neutral[4]}`,
    fontWeight: "bold",
    color: theme.color.neutral[2],
  },
});
export default memo(SidebarLink);

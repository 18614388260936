import { createUseStyles } from "react-jss";
import { FlowController } from "../../types/flow-control";
import Title from "../Common/Title";
import FlowControlTable from "../FlowControllers/Table/FlowControlTable";

interface DashboardProps {}

function Dashboard({}: DashboardProps) {
  const styles = useStyles();
  return <div className={styles.root}></div>;
}

const useStyles = createUseStyles({
  root: {},
});
export default Dashboard;

import classNames from "classnames";
import React, { memo } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";
import { DivElement } from "../../types/dom";

interface TabPanelProps extends DivElement {
  children?: React.ReactNode;
  className?: string;
}

function TabPanel({ children, className, ...rest }: TabPanelProps) {
  const styles = useTabPanelStyles();

  return (
    <div className={classNames(styles.root, className)} {...rest}>
      {children}
    </div>
  );
}

const useTabPanelStyles = createUseStyles({
  root: {
    display: "grid",
    gridTemplateColumns: `repeat(4, max-content)`,
    gridGap: theme.spacing[4],
  },
});

interface TabProps extends Omit<DivElement, "onClick"> {
  className?: string;
  children: React.ReactNode;
  value: string | number;
  disabled?: boolean;
  active?: boolean;
  onClick: (value: string | number) => void;
}

export function Tab({
  value,
  children,
  active,
  disabled,
  className,
  onClick,
  ...rest
}: TabProps) {
  const styles = useTabStyles();

  const handleClick = () => {
    onClick(value);
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "Enter") onClick(value);
  };

  return (
    <div
      className={classNames(styles.root, className)}
      onClick={handleClick}
      onKeyPress={handlePressEnter}
      {...rest}
    >
      <div className={classNames(styles.children, active && styles.active)}>
        {children}
      </div>
      <div className={classNames(styles.underline, !active && styles.hidden)} />
    </div>
  );
}

const useTabStyles = createUseStyles({
  root: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.color.neutral[3],
    fontSize: 18,
    cursor: "pointer",
  },
  active: {
    color: theme.color.neutral[0],
  },
  children: {
    // color: theme.color.neutral[4],
  },
  underline: {
    position: "relative",
    height: 2,
    background: theme.color.neutral[2],
    marginTop: 2,
    top: 2,
  },
  hidden: {
    visibility: "hidden",
  },
});

export default memo(TabPanel);

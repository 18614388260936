import { useState } from "react";
import { createUseStyles } from "react-jss";
import useInterval from "use-interval";
import { fetchEpcs, fetchMfcs } from "../server/flow-controllers";
import { theme } from "../style/theme";
import { EpcState, MfcState } from "../types/flow-control";
import { ServiceEndpoint } from "../types/network";
import FlowController from "./FlowControllers/FlowController";
import Button from "./Common/Button";
import { servicePorts } from "../App";

function FlowControllers({ ip, port }: ServiceEndpoint) {
  const styles = useStyles();

  const [epcs, setEpcs] = useState<EpcState[]>([]);
  const [mfcs, setMfcs] = useState<MfcState[]>([]);

  const updateFlowControllers = async () => {
    setEpcs(await fetchEpcs(ip, port));
    setMfcs(await fetchMfcs(ip, port));
  };
  useInterval(updateFlowControllers, 1000);

  return (
    <div className={styles.root}>
      <div className={styles.tool}>
        <div>
          <div>EPCs</div>
          <div>{epcs.length}</div>
        </div>
        <div>
          <div>MFCs</div>
          <div>{mfcs.length}</div>
        </div>
        <div>
          <Button
            className={styles.submitButton}
            onClick={() => {
              fetch(
                `http://${ip}:${servicePorts.monitor}/restart/service/flow-control`,
                {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                }
              );
            }}
          >
            Restart Flow Service
          </Button>
        </div>
      </div>
      {epcs.map((epc) => (
        <FlowController
          key={epc.id}
          className={styles.controller}
          id={epc.id}
          controller={epc}
          kind="epc"
        />
      ))}
      {mfcs.map((mfc) => (
        <FlowController
          key={mfc.id}
          className={styles.controller}
          id={mfc.id}
          controller={mfc}
          kind="mfc"
        />
      ))}
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    // display: "grid",
    // gridGap: theme.spacing[3],
    // height: "max-content",
    // justifyContent: "center",
    // width: "100%",
    // gridTemplateColumns: "repeat(auto-fit, 1fr)",
    // justifyItems: "center",
    display: "grid",
    gridGap: theme.spacing[3],
    gridTemplateColumns: "repeat(auto-fit, 300px)",
    height: "max-content",
    justifyContent: "center",
    marginTop: theme.spacing[4],
  },
  controller: {
    height: "max-content",
    width: 300,
  },
  tool: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing[3],
  },
  epcs: {},
  mfcs: {},
  submitButton: {
    padding: 8,
    fontWeight: "bold",
    background: theme.color.neutral[6],
    color: theme.color.neutral[0],
  },
});
export default FlowControllers;

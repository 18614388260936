import React, { ForwardedRef } from "react";
import { useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { DivElement } from "../../types/dom";
import Input from "./Input";

interface EditableFieldProps extends DivElement {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const EditableField = React.forwardRef(
  (props: EditableFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const styles = useStyles();
    const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
    const [_value, setValue] = useState<string>("");
    const { children, value, onChange, ...rest } = props;

    const displayValue = value ?? _value;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) return onChange(event);
      setValue(event.target.value);
    };

    const onFocus = (event: React.FocusEvent<HTMLDivElement, Element>) => {
      if (!isInEditMode) setIsInEditMode(true);

      // if (inputRef.current) inputRef.current.select();
      // if (ref.current) ref.current.select();
    };

    const disableInputMode = () => {
      if (isInEditMode) setIsInEditMode(false);
    };

    const enableInputMode = () => {
      if (!isInEditMode) setIsInEditMode(true);
    };

    return (
      <div
        className={styles.root}
        onClick={enableInputMode}
        onFocus={onFocus}
        onBlur={disableInputMode}
        {...rest}
      >
        {!isInEditMode ? (
          displayValue
        ) : (
          <Input
            autoFocus
            ref={ref}
            value={displayValue}
            onChange={handleChange}
          />
        )}
      </div>
    );
  }
);

const useStyles = createUseStyles({
  root: {},
});

export default EditableField;

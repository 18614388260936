import classNames from "classnames";
import { memo } from "react";
import { createUseStyles } from "react-jss";
import { DetectorState } from "../../hooks/detector";
import { theme } from "../../style/theme";
import TabPanel, { Tab } from "../Common/TabPanel";

interface DetectorSelectProps {
  detectors: DetectorState[];
  selected: number | undefined;
  onChange: (selected: string | number) => void;
  className?: string;
}

function DetectorSelect({
  detectors,
  onChange,
  selected,
  className,
}: DetectorSelectProps) {
  const styles = useStyles();

  return (
    <TabPanel className={classNames(styles.root, className)}>
      {detectors.map((d, i) => (
        <Tab
          key={d.id}
          value={d.id}
          onClick={() => onChange(d.id)}
          active={selected === d.id}
          tabIndex={i}
        >{`${d.kind} ${d.id}`}</Tab>
      ))}
    </TabPanel>
  );
}

const useStyles = createUseStyles({
  root: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: theme.spacing[3],
  },
});
export default memo(DetectorSelect);

import { memo } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";
import { DivElement } from "../../types/dom";

interface LinkGroupProps extends DivElement {
  title: string;
}

function LinkGroup(props: LinkGroupProps) {
  const styles = useStyles();
  const { children, title, ...rest } = props;

  return (
    <div className={styles.root} {...rest}>
      <div title={title} className={styles.title}>
        {title}
      </div>
      <div>{children}</div>
    </div>
  );
}

const useStyles = createUseStyles({
  root: {},
  title: {
    textTransform: "uppercase",
    fontSize: 12,
    paddingLeft: theme.spacing[2],
    color: "#6C7177",
    marginBottom: theme.spacing[1],
  },
});
export default memo(LinkGroup);

import { memo } from "react";
import { createUseStyles } from "react-jss";

import { theme } from "../../style/theme";
import { ServiceEndpoint } from "../../types/network";
import DioChannelsPanel from "./DioChannelsPanel";
import IgnitorsPanel from "./IgnitorsPanel";

function DigitalIOPanel({ ip, port }: ServiceEndpoint) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <DioChannelsPanel className={styles.panel} ip={ip} port={port} />
      <IgnitorsPanel className={styles.panel} ip={ip} port={port} />
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    display: "grid",
    gridGap: theme.spacing[4],
    justifyContent: "center",
    marginTop: theme.spacing[4],
    "@media (min-width: 768px)": {
      gridTemplateColumns: "repeat(2, max-content)",
    },
  },
  panel: {
    // borderRadius: theme.spacing[2],
  },
});
export default memo(DigitalIOPanel);

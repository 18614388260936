import { memo } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../style/theme";
import { DivElement } from "../../types/dom";

interface TopbarProps extends DivElement {}

function Topbar(props: TopbarProps) {
  const styles = useStyles();
  const { children, ...rest } = props;

  return (
    <div className={styles.root} {...rest}>
      {children}
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    marginBottom: theme.spacing[3],
    paddingLeft: theme.spacing[3],
    background: theme.color.neutral[9],
    padding: `${theme.spacing[4]}px 0`,
  },
});
export default memo(Topbar);

export const colors = [
  { value: "#1f77b4" },
  { value: "#ff7f0e" },
  { value: "#2ca02c" },
  { value: "#d62728" },
  { value: "#9467bd" },
  { value: "#8c564b" },
  { value: "#e377c2" },
  { value: "#7f7f7f" },
  { value: "#bcbd22" },
  { value: "#17becf" },
] as const;

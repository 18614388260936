import { useState } from "react";
import { createUseStyles } from "react-jss";
import useInterval from "use-interval";
import { useOvenIndex } from "../hooks/ovens";
import { fetchOvens } from "../server/ovens";
import { theme } from "../style/theme";
import { ServiceEndpoint } from "../types/network";
import { OvenState } from "../types/oven";
import OvenCard from "./Oven/OvenCard";

function Ovens({ ip, port }: ServiceEndpoint) {
  const ids = useOvenIndex(ip, port);
  const [ovens, setOvens] = useState<OvenState[]>([]);
  const styles = useStyles();

  const updateOvens = async () => {
    setOvens(await fetchOvens(ip, port, ids || []));
  };
  useInterval(updateOvens, 1000);

  if (ovens === null) return <div>No ovens found :(</div>;

  return (
    <div className={styles.root}>
      {ovens.map((oven) => (
        <OvenCard className={styles.oven} key={oven.id} state={oven} />
      ))}
      {/* <Modal defaultOpen={true}>
        <SetRamp />
      </Modal> */}
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    display: "grid",
    gridGap: theme.spacing[3],
    gridTemplateColumns: "repeat(auto-fit, 350px)",
    height: "max-content",
    justifyContent: "center",
    marginTop: theme.spacing[4],
  },
  oven: {
    maxWidth: 670,
    width: "max-content",
    height: "max-content",
  },
});

export default Ovens;

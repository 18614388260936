import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faStopCircle,
  faEraser,
} from "@fortawesome/free-solid-svg-icons";
import { memo, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  Data,
  DetectorState,
  useDetectorData,
  useDetectorState,
  useDetectorStatistics,
} from "../../hooks/detector";
import Button from "../Common/Button";
import Plot from "../Common/Plot";
import DetectorSelect from "./DetectorSelect";
import { theme } from "../../style/theme";
import classNames from "classnames";
import { useWebsocket } from "../../hooks/websocket";
import Label from "../Common/Label";
import { decimateDataset } from "../../math";
import { Viewport } from "../../types/plot";
import { useResizeDetector } from "react-resize-detector";
import Card from "../Common/Card";

interface DetectorsProps {
  ip: string;
  ports: { websocket: number; http: number };
}

function Detectors({ ip, ports }: DetectorsProps) {
  const styles = useStyles();
  const [detectors, setDetectors] = useDetectorState(ip, ports.http);
  const [selectedDetector, setSelectedDetector] = useState<
    DetectorState | undefined
  >();
  // useEffect(() => {
  //   setSelectedDetector(detectors[0]);
  // }, [detectors]);
  const websocket = useWebsocket(ip, ports.websocket);
  const [datasets, setDatasets] = useDetectorData(websocket, detectors);
  const dataset = datasets.filter((d) => d.id === selectedDetector?.id)[0];
  const [visibleData, setVisibleData] = useState<Data>(dataset);
  const [viewport, setViewport] = useState<Viewport>({
    x: undefined,
    y: undefined,
  });
  const { width, ref } = useResizeDetector();

  useEffect(() => {
    if (dataset) setVisibleData(decimateDataset(dataset, viewport, 1000));
  }, [datasets]);

  const { noise, standardDeviation } = useDetectorStatistics(visibleData);

  const handleChangeSelectedDetector = (selected: string | number) => {
    const detector = detectors.find((d) => d.id === selected);
    setSelectedDetector(detector);
  };

  const handleViewportChange = (viewport: Viewport) => {
    setViewport(viewport);
  };

  const clearFocusedData = () => {
    setDatasets(
      datasets.map((dataset) =>
        dataset.id === selectedDetector?.id
          ? { id: dataset.id, x: [], y: [], type: "scattergl" }
          : dataset
      )
    );
  };

  const toggleDetector = async () => {
    if (!selectedDetector) return;
    const { id, isRunning } = selectedDetector;
    if (!isRunning) clearFocusedData();
    const response = await fetch(
      `http://${ip}:${ports.http}/detector/${id}/${
        isRunning ? "stop" : "start"
      }`,
      { method: "POST" }
    );
    if (!response.ok) return;
    setDetectors(
      detectors.map((d) => ({
        ...d,
        isRunning: d.id === id ? !d.isRunning : d.isRunning,
      }))
    );
  };

  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.controlPanel}>
        <div className={styles.controls}>
          <DetectorSelect
            detectors={detectors}
            selected={selectedDetector?.id}
            onChange={handleChangeSelectedDetector}
            className={styles.detectorSelect}
          />
          <Button
            onClick={toggleDetector}
            className={classNames(styles.button, styles.solidButton)}
          >
            <FontAwesomeIcon
              icon={selectedDetector?.isRunning ? faStopCircle : faPlayCircle}
            />{" "}
            {`${selectedDetector?.isRunning ? "Stop" : "Start"} Detector`}
          </Button>
          <Button onClick={clearFocusedData} className={styles.button}>
            <FontAwesomeIcon icon={faEraser} /> Clear Data
          </Button>
        </div>
      </div>
      <div className={styles.plotContainer}>
        <Plot
          data={(visibleData && [visibleData]) || []}
          onChangeViewport={handleViewportChange}
          revision={visibleData?.x?.length || 0}
          width={width}
        />
      </div>
      <Card
        title="Statistics"
        className={styles.statistics}
        classes={{ content: styles.statsContent }}
      >
        <Label value="Noise">{noise.toFixed(4)}</Label>
        <Label value="STD">{standardDeviation.toFixed(4)}</Label>
      </Card>
    </div>
  );
}

const useStyles = createUseStyles({
  root: {
    height: "100%",
  },
  detectorSelect: {
    justifySelf: "left",
  },
  controlPanel: {},
  controls: {
    margin: theme.spacing[2],
    display: "grid",
    gridGap: theme.spacing[2],
    "@media (min-width: 600px)": {
      justifyContent: "end",
      justifyItems: "center",
      gridTemplateColumns: "1fr repeat(2, max-content)",
    },
  },
  button: {
    fontSize: 16,
    color: theme.color.neutral[1],
    padding: theme.spacing[1],
    "&:hover": {
      background: theme.color.neutral[7],
    },
  },
  solidButton: {
    background: theme.color.support.cyan[3],
    color: "white",
    "&:hover": {
      background: theme.color.support.cyan[4],
    },
  },
  plotContainer: {
    marginBottom: theme.spacing[2],
  },
  statistics: {
    width: theme.spacing[10],
    margin: "0 auto",
  },
  statsContent: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: theme.spacing[5],
  },
});
export default memo(Detectors);

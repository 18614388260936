import { BackplaneChannel, OvenState } from "../types/oven";

export async function fetchOvenIds(
  ip: string,
  port: number
): Promise<BackplaneChannel[] | null> {
  const ovenIndexReply = await fetch(`http://${ip}:${port}/ovens`);
  if (!ovenIndexReply.ok) return null;
  const { ids } = (await ovenIndexReply.json()) as { ids: BackplaneChannel[] };
  if (!ids.length) return [];

  return ids;
}

export async function fetchOvens(
  ip: string,
  port: number,
  ids: BackplaneChannel[]
): Promise<OvenState[]> {
  const ovenStateReplies = await Promise.all(
    ids.map((id) => fetch(`http://${ip}:${port}/oven/${id}`))
  );
  const ovens: OvenState[] = [];
  for (let reply of ovenStateReplies) {
    if (reply.ok) ovens.push((await reply.json()) as OvenState);
  }

  return ovens;
}

import { createUseStyles } from "react-jss";
import { useLoadedMethod } from "../../hooks/run";
import Input from "../Common/Input";

interface MethodProps {
  ip: string;
  ports: { method: number };
}

function Method({ ip, ports }: MethodProps) {
  const styles = useStyles();
  const method = useLoadedMethod(ip, ports.method);
  console.log(method);
  return <div className={styles.root}></div>;
}

const useStyles = createUseStyles({
  root: {},
});
export default Method;
